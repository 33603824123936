<template>
  <div class="flex flex-col gap-24">
    <div>
      <st-form-title
        :formTitle="$t('studio.group.collection.register_basic_info')"
        languageUse
        :referenceLanguage="$t(getLanguageNameI18n(selectedGroupInfo?.languageCd))"
        @onLanguageClick="onOpenMultilingualSettings"
      />
      <div class="flex flex-col gap-16 mt-8">
        <div>
          <st-form-title
            :formTitle="$t('studio.group.collection.register_basic_info_name')"
            subTitle
          />
          <input-text
            :placeholder="$t('studio.group.collection.register_basic_info_name_place_holder')"
            maxLength="50"
            name="basicProductInfos.productName"
            :disabled="!isMenuWritable || isDisabled"
            :rules="{
              max_length: 50,
              required: {
                value: true,
                message: $t('studio.group.collection.register_basic_info_name_msg1')
              },
              regex: {
                regex: REGEX_PRODUCT_NAME,
                message: $t('studio.prj_prod.this_prod.home_product_setting_basic_msg4_invalid')
              }
            }"
            :debounce="DEBOUNCE_INPUT_TIME"
            @blur="onProductNameChanged"
          />
        </div>
        <div>
          <st-form-title
            :formTitle="$t('studio.group.collection.register_basic_info_creator_name')"
            subTitle
          />
          <input-text
            :placeholder="
              $t('studio.group.collection.register_basic_info_creator_name_place_holder')
            "
            maxLength="50"
            name="basicProductInfos.developer"
            :disabled="!isMenuWritable || isDisabled"
            :rules="{
              max_length: 50,
              required: {
                value: true,
                message: $t('studio.group.collection.register_basic_info_creator_name_msg1')
              }
            }"
          />
        </div>
        <div>
          <st-form-title
            :formTitle="$t('studio.group.collection.register_basic_info_distributor_name')"
            subTitle
          />
          <input-text
            :placeholder="
              $t('studio.group.collection.register_basic_info_distributor_name_place_holder')
            "
            maxLength="50"
            :disabled="!isMenuWritable || isDisabled"
            name="basicProductInfos.publisher"
            :rules="{
              max_length: 50,
              required: {
                value: true,
                message: $t('studio.group.collection.register_basic_info_distributor_name_msg1')
              }
            }"
          />
        </div>
        <div>
          <st-form-title
            :formTitle="$t('studio.prj_prod.this_prod.home_product_setting_category')"
            required
            :count="true"
            :currentCount="selectedGenres.length"
            :maxCount="MAX_GENRE_COUNT"
            class="mt-32"
          />
          <div class="mt-8 flex flex-wrap gap-x-8 gap-y-12">
            <selectable-tag-list
              name="genreTagNos"
              :tagList="genreOptions"
              :hasLimit="true"
              :disabled="!isMenuWritable || isDisabled"
              :maxTag="MAX_GENRE_COUNT"
            />
            <p
              v-if="selectedGenres.length === 0 && isGenreTouched"
              class="flex items-start gap-[.2rem] mt-[.4rem] text-error text-xs leading-xs font-regular"
            >
              <s-icon
                icon="ic-v2-state-warning-circle-fill"
                size="xl"
                class="flex h-16 mt-[.1rem]"
              />
              {{ $t('studio.prj_prod.this_prod.home_product_setting_category_val_msg') }}
            </p>
          </div>
          <template v-if="selectedGenres?.length > 0">
            <div class="mt-20 border-t-1 border-solid border-border">
              <st-form-title
                required
                :formTitle="
                  $t('studio.prj_prod.this_prod.home_product_setting_representative_category')
                "
                class="mt-16"
              />
            </div>
            <div class="relative max-w-[24rem]">
              <s-dropdown
                :key="genreOptionKey"
                v-model="defaultGenreOption"
                size="sm"
                variant="line"
                :distance="0"
                :offset="[0, 0]"
                placement="bottom-start"
                class="w-full"
                :closeOnClick="true"
                :isDisabled="!isMenuWritable || isDisabled"
                @update:modelValue="
                  setDefaultGenreNo({
                    label: defaultGenreOption?.label,
                    value: defaultGenreOption?.value
                  })
                "
              >
                <s-dropdown-button class="w-full">
                  {{ defaultGenreOption?.label }}
                </s-dropdown-button>
                <template #dropdownItems>
                  <s-dropdown-item
                    v-for="genre in selectedGenres"
                    :key="genre?.value"
                    :value="{ value: genre?.value, label: genre?.label }"
                  >
                    <span class="truncate">{{ genre?.label }}</span>
                  </s-dropdown-item>
                </template>
              </s-dropdown>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="flex justify-center">
      <s-button
        v-if="mode === MODE.CREATE"
        variant="primary"
        size="md"
        class="!min-w-116"
        @click="createCollection"
      >
        {{ $t('studio.group.collection.register_title') }}
      </s-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { cloneDeep } from 'lodash';
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { checkProductInProjectNameApi } from '@/apis/project-product.api';
import { fetchTagsApi } from '@/apis/tag.api';
import SelectableTagList from '@/components/common/selectable-tag-list.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import BasicInfoMultilingual from '@/components/product-settings/basic-info-multilingual.vue';
import InputText from '@/components/validation/input-text.vue';
import { showAlert, showDialog } from '@/composables/useDialog';
import { DEBOUNCE_INPUT_TIME, MODE } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import { REGEX_PRODUCT_NAME } from '@/constants/regex.const';
import { LanguageCode } from '@/enums/language-code.enum';
import { TagType } from '@/enums/tag.enum';
import { RuleNames } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useCollectionStore } from '@/stores/collection.store';
import { useUserStore } from '@/stores/user.store';
import type { CollectionChild, CollectionInfos } from '@/types/collection/collection.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type { ProductLanguage } from '@/types/product/product-model.type';
import type { Tag } from '@/types/tags/tags-response.type';
import { getLanguageNameI18n } from '@/utils/common.util';
import { isNotTranslated } from '@/utils/product.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  validateField:(field: any) => Promise<{ valid: boolean }>;
  translatedValue: CollectionInfos[];
  isDisabled: boolean;
}>();

const emit = defineEmits<{
  createCollection: [];
  updateTranslatedValue: [v: ProductLanguage[]];
}>();

const MAX_GENRE_COUNT = 3;

const route = useRoute();
const { mode } = route.params;
// const { productNo } = route.query;

const userStore = useUserStore();
const { selectedGroupId, selectedGroupInfo } = storeToRefs(userStore);

const collectionStore = useCollectionStore();
const { selectedProductList } = storeToRefs(collectionStore);

const { isMenuWritable } = storeToRefs(useAppStore());

const { translatedValue } = toRefs(props);

const selectedProductsId = ref<number[]>([]);
// const currentPageId = ref<string>('');
const collectionStSearchArea = ref<HTMLElement | null>(null);
const hasSearched = ref<boolean>(false);
const { t } = useI18n();
const languages = ref<ProductLanguage[]>([
  {
    productName: '',
    developer: '',
    publisher: '',
    langCode: LanguageCode.Ko,
    default: selectedGroupInfo.value?.languageCd === LanguageCode.Ko
  },
  {
    productName: '',
    developer: '',
    publisher: '',
    langCode: LanguageCode.En,
    default: selectedGroupInfo.value?.languageCd === LanguageCode.En
  },
  {
    productName: '',
    developer: '',
    publisher: '',
    langCode: LanguageCode.Ja,
    default: selectedGroupInfo.value?.languageCd === LanguageCode.Ja
  },
  {
    productName: '',
    developer: '',
    publisher: '',
    langCode: LanguageCode.ZhCn,
    default: selectedGroupInfo.value?.languageCd === LanguageCode.ZhCn
  },
  {
    productName: '',
    developer: '',
    publisher: '',
    langCode: LanguageCode.ZhTw,
    default: selectedGroupInfo.value?.languageCd === LanguageCode.ZhTw
  }
]);
const products = ref<CollectionChild[]>([]);
const isInit = ref<boolean>(false);
const isGenreTouched = ref<boolean>(false);
const genreOptionKey = ref(0);
const defaultGenreOption = ref<FormOption>();

const duplicateProductError = ref(false);
const isCheckingProductName = ref(false);
const isClickedSubmit = ref(false);

const productName = useFieldValue<string>('basicProductInfos.productName');
const creatorName = useFieldValue<string>('basicProductInfos.developer');
const distributorName = useFieldValue<string>('basicProductInfos.publisher');
const childProducts = useFieldValue<CollectionChild[]>('childProducts');
const defaultGenreNo = useFieldValue<FormOption>('defaultGenre');
const genreTagNos = useFieldValue<string[]>('genreTagNos');
const productInfos = useFieldValue<CollectionInfos[]>('productInfo');

const setProductNameError = useSetFieldError('basicProductInfos.productName');
const setChildrenProductNos = useSetFieldValue('childrenProductNos');
const setProductInfos = useSetFieldValue('productInfos');
const setProductInfo = useSetFieldValue('productInfo');
const setDefaultGenreNo = useSetFieldValue('defaultGenre');

const productNameError = useFieldError('basicProductInfos.productName');

const selectedProducts = ref<CollectionChild[]>([]);
const genreOptions = ref<FormOption[]>([]);

const selectedGenres = computed(() => {
  return genreTagNos.value.map((tagNo: string) => {
    return genreOptions.value.find((tag: FormOption) => tag.value === tagNo);
  });
});

const currentProductName = computed(() => {
  return translatedValue.value.find(
    (product: CollectionInfos) => product.languageCd === selectedGroupInfo.value?.languageCd
  )?.productName;
});

const defaultLanguageIndex = computed(() => {
  return languages.value.findIndex((l: ProductLanguage) => l.default);
});

const triggerValidateBasicInformation = async () => {
  const { validateField } = props;
  const reqValue = []; // `${t('studio.ai_translation.title2_product')}, ${t('studio.ai_translation.title3_creator')}, ${t('studio.ai_translation.title4_distributor')}`;
  const productNameError = await validateField('basicProductInfos.productName');
  const developerError = await validateField('basicProductInfos.developer');
  const publisherError = await validateField('basicProductInfos.publisher');

  if (!productNameError.valid) {
    reqValue.push(t('studio.ai_translation.title2_product'));
  }

  if (!developerError.valid) {
    reqValue.push(t('studio.ai_translation.title3_creator'));
  }

  if (!publisherError.valid) {
    reqValue.push(t('studio.ai_translation.title4_distributor'));
  }

  return reqValue.join(', ');
};

const onOpenMultilingualSettings = async () => {
  const reqValue = await triggerValidateBasicInformation();
  if (!productName.value || !creatorName.value || !distributorName.value) {
    await showAlert({
      content: t('studio.common.popup_case_lang_setting_input_required', {
        reqValue
      }),
      confirmClasses: '!max-w-full !w-full',
      cancelClasses: 'stds-button stds-button-primary'
    });
    return;
  }

  if (productNameError.value) {
    return;
  }

  const result = await showDialog({
    component: shallowRef(BasicInfoMultilingual),
    props: {
      groupId: selectedGroupId.value,
      languages: languages.value,
      isDisabled: !isMenuWritable.value || props.isDisabled
    },
    severity: 'info'
  });
  if (!result) {
    return;
  }

  languages.value = cloneDeep(result) as ProductLanguage[];

  setProductInfos(
    languages.value.map((lang: ProductLanguage) => {
      return {
        languageCd: lang.langCode,
        productName: lang.productName,
        developer: lang.developer,
        publisher: lang.publisher
      };
    })
  );

  setProductInfo(
    languages.value.map((lang: ProductLanguage) => {
      return {
        languageCd: lang.langCode,
        productName: lang.productName,
        developer: lang.developer,
        publisher: lang.publisher
      };
    })
  );

  emit('updateTranslatedValue', languages.value);
};

const onProductNameChanged = async () => {
  if (
    currentProductName.value === productName.value.trim() ||
    !isMenuWritable.value ||
    props.isDisabled
  ) {
    return true;
  }
  try {
    isCheckingProductName.value = true;
    duplicateProductError.value = false;
    await checkProductInProjectNameApi({
      groupId: selectedGroupId.value,
      productName: productName.value.trim(),
      languageCd: 'ALL'
    });
  } catch (err) {
    const error = err as ErrorResponse;
    const errorCode = error.statusCode ?? 0;
    if (errorCode === STATUS_CODE.PRODUCT_NAME_DUPLICATED) {
      duplicateProductError.value = true;
      const errorMessage = generateErrorMsg(
        'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setProductNameError(errorMessage);
    }
  }
  if (isClickedSubmit.value) {
    progressSave();
  }
  isCheckingProductName.value = false;
};

const fetchGenres = async () => {
  const tags = (await fetchTagsApi({ tagType: TagType.Genre })) || [];
  genreOptions.value = tags.map((tag: Tag) => {
    return {
      label: tag.tagName,
      value: tag.tagNo.toString()
    };
  });
};

const createCollection = () => {
  isClickedSubmit.value = true;
  if (!isCheckingProductName.value) {
    progressSave();
  }
};

const progressSave = async () => {
  isClickedSubmit.value = false;
  isGenreTouched.value = true;

  const settingLanguages = languages.value.filter((lang: ProductLanguage) => !lang.default);
  const isEmptySettings = isNotTranslated(settingLanguages);

  const reqValue = await triggerValidateBasicInformation();
  if (duplicateProductError.value) {
    setTimeout(() => {
      const errorMessage = generateErrorMsg(
        'studio.prj_prod.this_prod.home_product_setting_val_duplication_msg',
        RuleNames.CHECK_DUPLICATE
      );
      setProductNameError(errorMessage);
    });
  }
  if (reqValue || selectedGenres.value.length === 0) {
    await showAlert({ content: t('studio.common.popup_case_ai_trans_required_field') });
    return;
  }

  if (isEmptySettings) {
    await showAlert({
      content: t('studio.common.popup_case_ai_not_entered')
    });

    onOpenMultilingualSettings();
    return;
  }

  emit('createCollection');
};

const init = async () => {
  await fetchGenres();
};

init();

watch(
  () => translatedValue.value,
  () => {
    languages.value = translatedValue.value.map((info: CollectionInfos) => {
      return {
        productName: info.productName,
        developer: info.developer,
        publisher: info.publisher,
        langCode: info.languageCd,
        default: info.languageCd === selectedGroupInfo.value?.languageCd
      };
    });
  },
  { once: true }
);

watch(selectedProducts, () => {
  selectedProductList.value = selectedProducts.value;
});

watch(
  () => selectedProductsId.value,
  () => {
    selectedProducts.value = products.value.filter((product: CollectionChild) => {
      return selectedProductsId.value.includes(product.productNo);
    });
  }
);

watch(
  () => childProducts.value,
  () => {
    products.value = childProducts.value;
    selectedProductsId.value = childProducts.value.map(
      (product: CollectionChild) => product.productNo
    );
  },
  {
    once: true
  }
);

watch(
  () => products.value,
  () => {
    if (mode === MODE.EDIT && !isInit.value) {
      selectedProducts.value = childProducts.value;
      isInit.value = true;
    }
    selectedProducts.value = products.value.filter((product: CollectionChild) =>
      selectedProductsId.value.includes(product.productNo)
    );
  }
);

watch(collectionStSearchArea, () => {
  if (collectionStSearchArea.value === null) {
    hasSearched.value = false;
  }
  setChildrenProductNos(selectedProductsId.value);
});

const isFirstTime = ref(true);

watch(genreTagNos, (newValue: string[], oldValue: string[]) => {
  if (oldValue.length === 0 && newValue.length === 1) {
    defaultGenreOption.value = genreOptions.value.find(
      (tag: FormOption) => tag.value === newValue[0]
    );
    setDefaultGenreNo(defaultGenreOption.value);
    isGenreTouched.value = true;
  } else {
    // get removed genre
    const removedGenre = oldValue.filter((tagNo: string) => !newValue.includes(tagNo));
    if (removedGenre[0] === defaultGenreOption.value?.value) {
      if (newValue.length > 0) {
        if (isFirstTime.value && mode === MODE.EDIT) {
          defaultGenreOption.value = genreOptions.value.find(
            (tag: FormOption) => tag.value === defaultGenreNo.value.value
          );
          isFirstTime.value = false;
        } else {
          defaultGenreOption.value = genreOptions.value.find(
            (tag: FormOption) => tag.value === newValue[0]
          );
        }
      } else {
        defaultGenreOption.value = undefined;
      }
      setDefaultGenreNo(defaultGenreOption.value);
    }

    genreOptionKey.value = new Date().getTime();
  }
});

watch(productName, (value: string) => {
  languages.value[defaultLanguageIndex.value].productName = value;
  const newProductInfos = productInfos.value.map((info: CollectionInfos) => {
    return {
      languageCd: info.languageCd,
      productName:
        info.languageCd === selectedGroupInfo.value?.languageCd ? value : info.productName,
      developer: info.developer,
      publisher: info.publisher
    };
  });

  setProductInfo(newProductInfos);
});

watch(creatorName, (value: string) => {
  languages.value[defaultLanguageIndex.value].developer = value;
  const newProductInfos = productInfos.value.map((info: CollectionInfos) => {
    return {
      languageCd: info.languageCd,
      productName: info.productName,
      developer: info.languageCd === selectedGroupInfo.value?.languageCd ? value : info.developer,
      publisher: info.publisher
    };
  });

  setProductInfo(newProductInfos);
});

watch(distributorName, (value: string) => {
  languages.value[defaultLanguageIndex.value].publisher = value;
  const newProductInfos = productInfos.value.map((info: CollectionInfos) => {
    return {
      languageCd: info.languageCd,
      productName: info.productName,
      developer: info.developer,
      publisher: info.languageCd === selectedGroupInfo.value?.languageCd ? value : info.publisher
    };
  });

  setProductInfo(newProductInfos);
});

watch(
  productInfos,
  (value: CollectionInfos[]) => {
    if (value.length === 0) {
      languages.value = languages.value.map((l: ProductLanguage) => {
        if (l.default) {
          return {
            langCode: l.langCode,
            default: true,
            productName: productName.value,
            developer: creatorName.value,
            publisher: distributorName.value
          };
        }
        return {
          langCode: l.langCode,
          default: false,
          productName: '',
          developer: '',
          publisher: ''
        };
      });
    }
  },
  {
    immediate: true,
    once: true
  }
);
</script>
