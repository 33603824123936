import { defineStore } from 'pinia';
import { ref } from 'vue';

import type { CollectionChild } from '@/types/collection/collection.type';

export const useCollectionStore = defineStore('collectionStore', () => {
  const selectedProductList = ref<CollectionChild[]>([]);

  const emptySelectedProductList = () => {
    selectedProductList.value = [];
  };

  return {
    selectedProductList,
    emptySelectedProductList
  };
});
