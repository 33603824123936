<template>
  <s-dialog to="stds-dialog-overview" size="lg" variant="fullpage" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="!h-full !w-full !max-h-full !rounded-none">
      <s-dialog-content class="flex-1 !m-0 !p-0">
        <s-dialog-content-body class="flex h-full flex-col !m-0">
          <full-product-page-detail
            :productPageName="productName"
            :productNo="productNo"
            :source="returnSource"
            :type="'dialog'"
            :pageSimpleId="pageId"
            :childProductNos="childProductNos"
            @onClose="closeDialog"
          />
        </s-dialog-content-body>
      </s-dialog-content>
    </s-dialog-panel>
  </s-dialog>

  <s-portal-target name="stds-dialog-overview" />
</template>
<script lang="ts" setup>
import { toRefs } from 'vue';

import FullProductPageDetail from '@/components/product-page/full-product-page-detail.vue';
import type { ProductPageFormType } from '@/types/product-page.type';

const props = defineProps<{
  productName: string;
  productNo: number;
  returnSource: string;
  pageId: string;
  childProductNos: number[];
}>();

const { productName } = toRefs(props);

const emits = defineEmits<{
  close: [p?: ProductPageFormType];
}>();

const closeDialog = (page?: ProductPageFormType) => {
  emits('close', page);
};
</script>
