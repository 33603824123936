<template>
  <s-dialog to="stds-dialog-country-prod" size="sm" :open="true">
    <s-dialog-overlay />
    <s-dialog-panel class="relative !w-[93.2rem] rounded-[2.8rem]">
      <st-dialog-header class="pt-[2.2rem] px-32 pb-[1.8rem]" @clickClose="closeDialog">
        <s-text as="h3" role="title8" class="text-on-surface-elevation-1 font-bold">
          {{ $t('studio.group.collection.register_product_config_nation_btn') }}
        </s-text>
      </st-dialog-header>
      <s-dialog-content class="px-32 pb-32 m-0">
        <div class="flex items-center gap-8">
          <s-text as="h4" role="text3" class="text-on-surface-elevation-2 font-bold">
            {{ $t('studio.group.collection.register_product_config_nation_btn_select_region') }}
          </s-text>
          <div class="w-180 relative">
            <dropdown
              v-model="selectedRegion"
              :options="sortedRegions"
              :dropdownProps="{
                size: 'lg',
                variant: 'line',
                distance: 4,
                offset: [0],
                placement: 'bottom',
                class: 'w-full'
              }"
            />
          </div>
        </div>
        <div
          class="relative min-w-full h-[48.2rem] overflow-auto mt-12 -mr-12 -mb-12 studio-scrollbar-4"
        >
          <st-table
            :columns="columns"
            :data="selectedProducts"
            headerSticky
            bodyClass="bg-surface-elevation-1"
          >
            <template #row="{ row }">
              <st-tr>
                <st-td aLeft class="sticky left-0 z-[10] bg-surface-elevation-1">
                  <s-text
                    as="span"
                    role="text4"
                    class="font-medium block text-nowrap text-ellipsis truncate overflow-hidden"
                  >
                    {{ row.productName }}
                  </s-text>
                </st-td>
                <st-td aLeft class="bg-surface-elevation-1">
                  <s-text as="span" role="text4" class="font-medium"> {{ row.productNo }} </s-text>
                </st-td>
                <st-td aLeft class="bg-surface-elevation-1">
                  <s-text as="span" role="text4" class="font-medium">
                    {{ getProductTypeLabel(row) }}
                  </s-text>
                </st-td>
                <template v-for="c in sortedCountryByRegion" :key="c.code">
                  <st-td aCenter class="bg-surface-elevation-1">
                    <s-icon
                      :icon="
                        getNationCheck(row.productNo, c.code)
                          ? 'ic-v2-control-check-bold-line'
                          : 'ic-v2-control-close-line'
                      "
                      size="xl"
                      :class="
                        getNationCheck(row.productNo, c.code)
                          ? 'text-on-surface-elevation-2'
                          : 'text-disabled-variant-1'
                      "
                    />
                  </st-td>
                </template>
              </st-tr>
            </template>
          </st-table>
        </div>
      </s-dialog-content>
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="stds-dialog-country-prod" />
</template>
<script setup lang="ts">
import { computed, ref } from 'vue';

import { fetchProductPageSelectedNations } from '@/apis/product-page.api';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import StTr from '@/components/common/table/st-tr.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { CORRECT_REGION_ORDER } from '@/constants/national-list.const';
import { PRODUCT_TYPE } from '@/constants/products.const';
import { useCountryStore } from '@/stores/country.store';
import type { CollectionChild } from '@/types/collection/collection.type';
import type { CommonCountry } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  ProductNation,
  ProductPageSelectedNationsResponse
} from '@/types/product-page/product-page-response.type';
import type { Column } from '@/types/table.type';
import { capitalizeFirstLetter } from '@/utils/string.util';

const emits = defineEmits<{
  close: [];
}>();

const props = defineProps<{
  selectedProducts: CollectionChild[];
}>();

const countryStore = useCountryStore();
const { sortedCountryList, regionOptions } = countryStore;

const sortedCountryByRegion = computed(() => {
  return sortedCountryList.filter((c: CommonCountry) => c.region === selectedRegion.value);
});

const sortedRegions = regionOptions.sort(
  (a: FormOption<string, unknown>, b: FormOption<string, unknown>) => {
    return CORRECT_REGION_ORDER.indexOf(a.value) - CORRECT_REGION_ORDER.indexOf(b.value);
  }
);

const productNationInfos = ref<ProductPageSelectedNationsResponse>();

const columns = computed<Column[]>(() => [
  { title: 'studio.group.collection.register_product_config_list_name', width: '32rem' },
  {
    title: 'studio.group.collection.register_product_config_list_no',
    width: '9.2rem',
    thClass: '!px-8 text-center'
  },
  {
    title: 'studio.group.collection.register_product_config_list_type',
    width: '9.2rem',
    thClass: '!px-8 text-center'
  },
  ...sortedCountryByRegion.value.map((country: CommonCountry) => ({
    title: `studio.national_code.${country.code.toLowerCase()}`,
    width: '9.2rem',
    thClass: '!px-8 !text-center',
    thTitleClass: 'truncate w-full',
    titleTooltip: `studio.national_code.${country.code.toLowerCase()}`
  }))
]);

const selectedRegion = ref(sortedRegions[0].value);

const closeDialog = () => {
  emits('close');
};

const getNationCheck = (productNo: number, countryCode: string) => {
  if (!productNationInfos.value) {
    return false;
  }
  const productNationInfo = productNationInfos.value.productNations.find(
    (p: ProductNation) => p.productNo === productNo
  );
  if (!productNationInfo) {
    return false;
  }
  return productNationInfo.selectedNations.includes(countryCode);
};

const init = async () => {
  const result = await fetchProductPageSelectedNations(
    props.selectedProducts.map((p: CollectionChild) => p.productNo)
  );
  if (result) {
    productNationInfos.value = result;
  }
};

const getProductTypeLabel = (product: CollectionChild) => {
  return `${capitalizeFirstLetter(product.productType)}${
    product.productDetailType === PRODUCT_TYPE.DLC ? ` ${product.productDetailType}` : ''
  }`;
};

init();
</script>
