<template>
  <div class="flex flex-col gap-24">
    <div>
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_rectangular_image_title')"
      />
      <input-product-file
        mode="cover"
        resolution="960x882"
        name="bannerImages.files[0]"
        :inList="false"
        :extensions="extensions"
        :type="FILE_TYPE.IMAGE"
        :cropBox="{
          width: 960,
          height: 882
        }"
        :stencilSize="{
          width: 400,
          height: 368
        }"
        :safeArea="{
          width: 290,
          height: 290
        }"
        :original="false"
        disableOpacity
        isHiddenAddLogo
        :isDisabled="!isMenuWritable || isDisabled"
        isZoomLimit
        isGuideShown
        expandFileNameByRightWing
        @update:modelValue="onRemoveFile('image2x2')"
      />
    </div>
    <div>
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_portrait_image_title')"
      />
      <input-product-file
        :fileNameWidth="350"
        :expandedWidth="600"
        mode="cover"
        resolution="366x771"
        name="bannerImages.files[1]"
        :inList="false"
        :extensions="extensions"
        :type="FILE_TYPE.IMAGE"
        :cropBox="{
          width: 366,
          height: 771
        }"
        :safeArea="{
          width: 138,
          height: 246
        }"
        :stencilSize="{
          width: 190,
          height: 400
        }"
        :original="false"
        disableOpacity
        isHiddenAddLogo
        :isDisabled="!isMenuWritable || isDisabled"
        isZoomLimit
        isGuideShown
        expandFileNameByRightWing
        @update:modelValue="onRemoveFile('image1x2')"
      />
    </div>
    <div>
      <st-form-title
        :formTitle="$t('studio.prj_prod.this_prod.marketing_banner_landscape_image_title')"
      />
      <input-product-file
        mode="cover"
        resolution="960x426"
        name="bannerImages.files[2]"
        :inList="false"
        :extensions="extensions"
        :type="FILE_TYPE.IMAGE"
        :cropBox="{
          width: 960,
          height: 426
        }"
        :safeArea="{
          width: 428,
          height: 192
        }"
        :stencilSize="{
          width: 680,
          height: 302
        }"
        :original="false"
        disableOpacity
        isHiddenAddLogo
        :isDisabled="!isMenuWritable || isDisabled"
        isZoomLimit
        isGuideShown
        expandFileNameByRightWing
        @update:modelValue="onRemoveFile('image2x1')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';

import StFormTitle from '@/components/common/st-form-title.vue';
import InputProductFile from '@/components/product-page/input-product-file.vue';
import { IMAGE_TYPE } from '@/constants/file.const';
import { FILE_TYPE } from '@/enums/product-page.enum';
import { useAppStore } from '@/stores/app.store';

defineProps<{
  isDisabled?: boolean;
}>();

const extensions = [IMAGE_TYPE.JPG, IMAGE_TYPE.PNG, IMAGE_TYPE.GIF, IMAGE_TYPE.WEBP];

const { isMenuWritable } = storeToRefs(useAppStore());

const onRemoveFile = (_name: string) => {
  // console.log(name);
};
</script>
