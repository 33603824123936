<template>
  <div class="flex flex-col gap-24">
    <div>
      <st-form-title
        :formTitle="$t('studio.group.collection.register_sales_settings_previous_price')"
      />
      <s-text as="p" role="title5" class="!font-bold">
        {{ currencySymbol }}{{ formatPrice(collectionPrice, currencyStandardDisplay) }}
      </s-text>
    </div>
    <div v-show="false">
      <div v-if="currentStatus !== PLAN_STATUS.RELEASE" class="flex flex-col gap-12">
        <st-form-title
          :formTitle="$t('studio.group.collection.register_launch_discount')"
          class="!mb-0"
        />
        <radio-group
          name="productPlan.release.sale.releaseDiscountYn"
          :options="collectionDiscountTypeOpts"
          :disabled="!isMenuWritable || isDisabled"
          :optionProps="{
            size: 'sm',
            align: 'middle'
          }"
          class="flex-1 flex items-center gap-24"
        />
      </div>
      <div v-show="collectionDiscountType === Confirmation.YES">
        <div>
          <st-form-title :formTitle="$t('studio.group.collection.register_discount_period')" />
          <div class="flex items-start gap-8">
            <s-text
              as="span"
              role="text3"
              class="font-medium text-on-surface-elevation-3 !leading-[3.2rem]"
            >
              {{ $t('studio.group.collection.register_discount_period_text1') }}
            </s-text>
            <span class="w-160">
              <input-text
                size="sm"
                variant="outline"
                :placeholder="$t('studio.group.collection.register_discount_period_placeholder')"
                inputClass="text-on-surface-elevation-1 text-right tracking-[0]"
                :disabled="!isMenuWritable || isDisabled"
                :rules="discountDaysRules"
                name="productPlan.release.sale.releaseDiscountDays"
                :min="1"
                :max="99"
                :type="InputTextTypes.Number"
              />
            </span>
            <s-text
              as="span"
              role="text3"
              class="font-medium text-on-surface-elevation-3 !leading-[3.2rem]"
            >
              {{ $t('studio.group.collection.register_discount_period_text2') }}
            </s-text>
          </div>
        </div>

        <div>
          <st-form-title
            :formTitle="$t('studio.group.collection.register_discount_rate')"
            class="mt-32"
          />
          <div class="flex items-start gap-8">
            <div class="w-160">
              <input-text
                size="sm"
                variant="outline"
                :placeholder="$t('studio.group.collection.register_discount_rate_placeholder')"
                inputClass="text-right tracking-[0]"
                :disabled="!isMenuWritable || isDisabled"
                :rules="discountRateRules"
                name="productPlan.release.sale.releaseDiscountRate"
                :min="0"
                :max="100"
                :type="InputTextTypes.Number"
              />
            </div>
            <s-text
              as="span"
              role="text3"
              class="font-medium text-on-surface-elevation-3 !leading-[3.2rem]"
            >
              %
            </s-text>
          </div>
        </div>
      </div>
    </div>

    <div>
      <st-form-title :formTitle="$t('studio.group.collection.register_price_preview')" />
      <s-text as="p" role="cap1" class="text-placeholder">
        <safe-html
          tag="span"
          :html="$t('studio.group.collection.register_price_preview_guide')"
        />
      </s-text>
      <div class="mt-16">
        <div
          class="flex justify-end gap-8"
          :class="{ customItemCollectionSize: locale === DEFAULT_LOCALES.en.code }"
        >
          <dropdown
            v-model="currency"
            containerClass="w-180 relative"
            :dropdownProps="{
              class: 'w-full'
            }"
            name="currencyStandard"
            :options="currencyStandardOptions"
          />
          <dropdown
            v-model="priceType"
            name="priceStandard"
            containerClass="w-180 relative"
            :dropdownProps="{
              class: 'w-full'
            }"
            :options="priceStandard"
          />
        </div>
        <s-text as="p" role="text4" class="flex justify-between mt-20">
          <span>{{ $t('studio.group.collection.register_price_preview_sub_title1') }}</span>
          <span>{{
            $t('studio.group.collection.register_price_preview_sub_title2', {
              prodNum: productList.length
            })
          }}</span>
        </s-text>
        <div class="rounded-xl bg-[#F7FAFD] p-20 mt-8">
          <template v-if="productList.length > 0">
            <ul class="flex flex-col gap-8 pb-16">
              <li
                v-for="product in productList"
                :key="product.id"
                class="flex justify-between text-on-surface-elevation-2"
              >
                <s-text as="span" role="text3" class="font-medium">{{ product.name }}</s-text>
                <s-text
                  v-if="!product.discountRate"
                  as="span"
                  role="text3"
                  class="text-on-surface-elevation-3"
                >
                  {{ currencySymbol
                  }}{{ formatPrice(getExchangedPrice(product), currencyStandardDisplay) }}
                </s-text>
                <s-text
                  v-else
                  as="span"
                  role="text3"
                  class="flex gap-8 text-on-surface-elevation-3"
                >
                  <span
                    aria-label="할인율 퍼센트"
                    class="text-tint-red-a400 font-bold"
                  >{{ product.discountRate }}%</span>
                  <span
                    aria-label="이전 가격"
                    class="line-through"
                  >{{ currencySymbol
                  }}{{
                    formatPrice(getExchangedPrice(product, true), currencyStandardDisplay)
                  }}</span>
                  <span aria-label="새로운 가격">{{ currencySymbol
                  }}{{
                    formatPrice(
                      getExchangedDiscountPrice(getExchangedPrice(product, true), product),
                      currencyStandardDisplay
                    )
                  }}</span>
                </s-text>
              </li>
            </ul>

            <!-- <div v-show="false" class="border-t border-solid border-border pt-16">
              <s-text as="p" role="text3" class="flex justify-between">
                <span class="text-on-surface-elevation-2 font-bold">{{
                  $t('studio.group.collection.register_price_preview_pay_info1')
                }}</span>
                <span class="text-on-surface-elevation-3">{{ currencySymbol
                }}{{ formatPrice(collectionPrice, currencyStandardDisplay) }}</span>
              </s-text>
              <div class="flex flex-col py-16 pl-16 gap-8">
                <s-text as="p" role="text4" class="flex justify-between">
                  <span class="text-on-surface-elevation-3">{{
                    $t('studio.group.collection.register_price_preview_pay_info2')
                  }}</span>
                  <span class="text-tint-red-a400 font-bold">-{{ releaseDiscountRate > 0 ? releaseDiscountRate : 0 }}%</span>
                </s-text>
                <s-text as="p" role="text4" class="flex justify-between">
                  <span class="text-on-surface-elevation-3">{{
                    $t('studio.group.collection.register_price_preview_pay_info3')
                  }}</span>
                  <span class="text-tint-red-a400 font-bold">-{{ currencySymbol
                  }}{{ formatPrice(collectionDiscountAmount, currencyStandardDisplay) }}</span>
                </s-text>
              </div>
            </div> -->

            <div
              class="flex justify-between text-on-surface-elevation-2 border-t border-solid border-border pt-16"
            >
              <s-text as="span" role="text3" class="font-bold">
                {{ $t('studio.group.collection.register_price_preview_pay_info4') }}
              </s-text>
              <s-text as="span" role="title5" class="font-bold">
                {{ currencySymbol
                }}{{ formatPrice(collectionFinalPrice, currencyStandardDisplay) }}
              </s-text>
            </div>
          </template>
          <p
            v-else
            class="h-120 flex items-center justify-center text-sm text-on-surface-elevation-4"
          >
            {{ $t('studio.group.collection.register_product_config_list_msg4') }}
          </p>
        </div>
      </div>
    </div>
    <div>
      <st-form-title :formTitle="$t('studio.group.collection.register_limited_sale')" />
      <s-text as="p" role="cap1" class="text-placeholder">
        {{ $t('studio.group.collection.register_limited_sale_guide') }}
      </s-text>
      <radio-group
        name="productPlan.release.sale.saleLimitType"
        :options="limitedSaleSettingOpts"
        :disabled="!isMenuWritable || isDisabled || currentStatus === PLAN_STATUS.RELEASE"
        :optionProps="{
          size: 'sm',
          align: 'middle'
        }"
        class="flex-1 flex items-center gap-24 mt-8 collections-sale"
      />
    </div>

    <div v-if="limitedSaleSetting === RELEASE_PLAN_LIMITED_SALES_STATUS.COUNT">
      <st-form-title :formTitle="$t('studio.group.collection.register_sales_quantity')" />
      <div class="flex items-start gap-8">
        <span class="w-200">
          <input-text
            name="productPlan.release.sale.saleLimitCount"
            placeholder="1~1,000,000"
            size="sm"
            containerClass="text-on-surface-elevation-1 text-right tracking-[0]"
            inputClass="text-right tracking-[0]"
            :disabled="!isMenuWritable || isDisabled || currentStatus === PLAN_STATUS.RELEASE"
            :rules="{
              required: {
                value: true,
                message: $t('studio.group.collection.register_sales_quantity_msg1')
              },
              number: {
                value: true,
                message: $t('studio.common.def_key.number_only_y')
              }
            }"
            :type="InputTextTypes.Number"
            :min="1"
            :max="1000000"
          />
        </span>
        <s-text
          as="span"
          role="text3"
          class="font-medium text-on-surface-elevation-3 !leading-[3.2rem]"
        >
          {{ $t('studio.group.collection.register_sales_quantity_text') }}
        </s-text>
      </div>
    </div>

    <div v-if="limitedSaleSetting === RELEASE_PLAN_LIMITED_SALES_STATUS.DATE">
      <st-form-title
        :formTitle="$t('studio.group.collection.register_end_date')"
        :utcTimezone="`(${utcTimezone})`"
      />
      <div class="flex flex-col gap-16 bg-surface-elevation-2 rounded-xl py-16">
        <datetime-picker
          name="productPlan.release.sale.saleLimitEndedAt"
          :isDisabled="!isMenuWritable || isDisabled || currentStatus === PLAN_STATUS.RELEASE"
          :rules="{
            required: {
              value: true,
              message: $t('studio.group.collection.register_end_date_msg1')
            },
            less_than_current_time: {
              value: true,
              message: $t('studio.calendar_event_period.val_msg_start_time_before_current_time')
            }
          }"
          containerClass="!py-0 !bg-transparent"
          :disabledDates="disabledDates"
          noShowError
        />
      </div>
      <st-error-message
        v-for="(error, index) in periodError"
        :key="index"
        class="mt-8"
        :errorMessage="error"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import { fetchProductDiscountsApi } from '@/apis/product-discount.api';
import { fetchProductsSellingPriceApi } from '@/apis/product-selling-price.api';
import SafeHtml from '@/components/common/safe-html.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import InputText from '@/components/validation/input-text.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { currencyCodes, NON_DIGIT_CURRENCY_CODES } from '@/constants/currency-codes.const';
import { DEFAULT_LOCALES } from '@/constants/locale.const';
import { PLAN_STATUS } from '@/constants/products.const';
import { RELEASE_PLAN_LIMITED_SALES_STATUS } from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { InputTextTypes } from '@/enums/validation.enum';
import { useAppStore } from '@/stores/app.store';
import { useCollectionStore } from '@/stores/collection.store';
import { useCurrencyStore } from '@/stores/currency.store';
import type { CollectionChild, CollectionProductItem } from '@/types/collection/collection.type';
import type { FormOption } from '@/types/common/form.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductDiscountResponse } from '@/types/product-discount/product-discount-response.type';
import type {
  PriceAppliedType,
  PriceListType
} from '@/types/product-selling-price/product-selling-price.type';
import {
  convertCurrencyToSymbol,
  formatPrice,
  getRoundedDownMoney,
  getVndDigitsPrice,
  truncateToDecimals
} from '@/utils/currency.util';
import { getUtcTimezone } from '@/utils/date.util';
import { displayPriceAfterDiscount, getDiscountAmount, paymentPriceAfterDiscount } from '@/utils/price.util';
import { generateErrorMsg } from '@/utils/validation.util';

const PRICE_STANDARD_VALUES = {
  STORE: 'store',
  PAYMENT: 'payment'
};

const props = defineProps<{
  isDisabled?: boolean;
  currentStatus: string;
}>();

const emit = defineEmits<{
  updateCollectionPriceList: [v: PriceListType[]];
}>();

const utcTimezone = getUtcTimezone();

const { t, locale } = useI18n();

const { isMenuWritable } = storeToRefs(useAppStore());

const collectionStore = useCollectionStore();
const { selectedProductList } = storeToRefs(collectionStore);

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const today = DateTime.local().startOf('day').toJSDate();
const disabledDates = {
  to: today
};

// const price = useFieldValue<number>('priceInfo.defaultPrice');
const setPrice = useSetFieldValue('priceInfo.defaultPrice');
const currencyStandard = useFieldValue<string>('currencyStandard');
const releaseDiscountRate = useFieldValue<number>('productPlan.release.sale.releaseDiscountRate');
const collectionDiscountType = useFieldValue<string>('productPlan.release.sale.releaseDiscountYn');
const limitedSaleSetting = useFieldValue<string>('productPlan.release.sale.saleLimitType');
const officialReleaseDate = useFieldValue<number>('productPlan.release.releasedAt');
const endedAt = useFieldValue<number>('productPlan.release.sale.saleLimitEndedAt');
const { currentStatus } = toRefs(props);

const endedAtError = useFieldError('productPlan.release.sale.saleLimitEndedAt');

const setIsValid = useSetFieldValue<string[]>('saleSettingErrors');

const exchangeRates = ref<CurrencyInfoModel[]>([]);

const currency = ref<CurrencyCode>(CurrencyCode.Usd);
const priceType = ref(PRICE_STANDARD_VALUES.STORE);

const currencySymbol = computed(() => {
  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT && currency.value !== CurrencyCode.Krw) {
    return convertCurrencyToSymbol(CurrencyCode.Usd);
  }
  return convertCurrencyToSymbol(currencyStandard.value as CurrencyCode);
});

const currencyStandardDisplay = computed(() => {
  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT && currency.value !== CurrencyCode.Krw) {
    return CurrencyCode.Usd;
  }
  return currencyStandard.value;
});

const collectionDiscountTypeOpts = ref<FormOption[]>([
  { label: t('studio.group.collection.register_launch_discount_y'), value: Confirmation.YES },
  { label: t('studio.group.collection.register_launch_discount_n'), value: Confirmation.NO }
]);

const priceList = ref<PriceAppliedType[]>([]);
const discountList = ref<ProductDiscountResponse[]>([]);

const limitedSaleSettingOpts = ref<FormOption[]>([
  {
    label: t('studio.group.collection.register_limited_sale_option1'),
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.COUNT
  },
  {
    label: t('studio.group.collection.register_limited_sale_option2'),
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.DATE
  },
  {
    label: t('studio.group.collection.register_limited_sale_option3'),
    value: RELEASE_PLAN_LIMITED_SALES_STATUS.NONE
  }
]);

const collectionPrice = computed(() => {
  return Number(
    productList.value
      .reduce((acc: number, product: CollectionProductItem) => {
        return acc + getExchangedDiscountPrice(getExchangedPrice(product, true, true) || 0, product);
      }, 0)
      .toFixed(2)
  );
});

const collectionDiscountAmount = computed(() => {
  if (releaseDiscountRate.value < 0) {
    return collectionPrice.value;
  }
  if (
    priceType.value === PRICE_STANDARD_VALUES.STORE ||
    currencyStandard.value === CurrencyCode.Krw
  ) {
    return getDiscountAmount(
      collectionPrice.value,
      releaseDiscountRate.value,
      currencyStandard.value
    );
  }

  return getDiscountAmount(collectionPrice.value, releaseDiscountRate.value, CurrencyCode.Usd);
});

const collectionFinalPrice = computed(() => {
  if (
    priceType.value === PRICE_STANDARD_VALUES.STORE ||
    currencyStandard.value === CurrencyCode.Krw
  ) {
    if (NON_DIGIT_CURRENCY_CODES.includes(currencyStandard.value as CurrencyCode)) {
      if (currencyStandard.value === CurrencyCode.Krw) {
        return getRoundedDownMoney(
          collectionPrice.value - (collectionPrice.value * (releaseDiscountRate.value ?? 0)) / 100
        );
      }
      if (currencyStandard.value === CurrencyCode.Vnd) {
        return getVndDigitsPrice(
          collectionPrice.value - (collectionPrice.value * (releaseDiscountRate.value ?? 0)) / 100
        );
      }

      return Math.floor(((collectionPrice.value - collectionDiscountAmount.value) / 10) * 10);
    }
  }
  return Number((collectionPrice.value - collectionDiscountAmount.value).toFixed(2));
});

const currencyStandardOptions = ref<FormOption[]>([
  {
    label: `${t('studio.national_code.us')} USD`,
    value: 'USD'
  },
  {
    label: `${t('studio.national_code.kr')} KRW`,
    value: 'KRW'
  },
  {
    label: `${t('studio.national_code.cn')} CNY`,
    value: 'CNY'
  },
  {
    label: `${t(
      'studio.prj_prod.this_prod.prod_mgmt_price_reg_regional_prices_displayed_price_eur_msg'
    )} EUR`,
    value: 'EUR'
  },
  {
    label: `${t('studio.national_code.gb')} GBP`,
    value: 'GBP'
  },
  {
    label: `${t('studio.national_code.hk')} HKD`,
    value: 'HKD'
  },
  {
    label: `${t('studio.national_code.id')} IDR`,
    value: 'IDR'
  },
  {
    label: `${t('studio.national_code.jp')} JPY`,
    value: 'JPY'
  },
  {
    label: `${t('studio.national_code.my')} MYR`,
    value: 'MYR'
  },
  {
    label: `${t('studio.national_code.ph')} PHP`,
    value: 'PHP'
  },
  {
    label: `${t('studio.national_code.sg')} SGD`,
    value: 'SGD'
  },
  {
    label: `${t('studio.national_code.th')} THB`,
    value: 'THB'
  },
  {
    label: `${t('studio.national_code.vn')} VND`,
    value: 'VND'
  }
]);

const initCurrencyList = ref<PriceListType[]>(
  currencyCodes.map((code: CurrencyCode) => {
    return {
      displayCurrencyCode: code,
      salesCurrencyCode: code === CurrencyCode.Krw ? CurrencyCode.Krw : CurrencyCode.Usd,
      salesPrice: 0,
      displayPrice: 0
    };
  })
);

const getExchangeInfo = (currencyCode: string) => {
  return exchangeRates.value?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const collectionPriceList = computed(() => {
  return initCurrencyList.value.map((curr: PriceListType) => {
    let salesPrice = 0;
    let displayPrice = 0;
    productList.value.forEach((product: CollectionProductItem) => {
      const foundCurrency = product.priceList?.find(
        (currency: PriceListType) => currency.displayCurrencyCode === curr.displayCurrencyCode
      );

      if (foundCurrency) {
        salesPrice += paymentPriceAfterDiscount(
          foundCurrency.salesPrice,
          product.discountRate ?? 0,
          curr.salesCurrencyCode ?? CurrencyCode.Usd
        );
        displayPrice += displayPriceAfterDiscount(
          foundCurrency.salesPrice,
          product.discountRate ?? 0,
          curr.displayCurrencyCode,
          getExchangeInfo(curr.displayCurrencyCode)?.exchangeRate
        );
      }
    });

    curr.salesPrice = Number(salesPrice.toFixed(2));
    curr.displayPrice = displayPriceAfterDiscount(
      Number(displayPrice.toFixed(2)),
      0,
      curr.displayCurrencyCode as CurrencyCode,
      1
    );

    return curr;
  });
});

const priceStandard = ref<FormOption[]>([
  {
    label: t('studio.group.collection.register_price_preview_select1'),
    value: PRICE_STANDARD_VALUES.STORE
  },
  {
    label: t('studio.group.collection.register_price_preview_select2'),
    value: PRICE_STANDARD_VALUES.PAYMENT
  }
]);

const productList = computed<CollectionProductItem[]>(() =>
  selectedProductList.value.map((product: CollectionChild) => {
    const price =
      priceList.value.find((price: PriceAppliedType) => price.productNo === product.productNo)
        ?.defaultPrice || 0;
    const discount =
      discountList.value.find(
        (discount: ProductDiscountResponse) => discount.productNo === product.productNo
      )?.discountRate || 0;
    return {
      id: product.productNo,
      name: product.productName,
      price,
      priceList:
        priceList.value.find((price: PriceAppliedType) => price.productNo === product.productNo)
          ?.priceList ?? [],
      discountRate: discount,
      discountPrice: price - price * (discount / 100)
    };
  })
);

const discountRateRules = computed(() => {
  const rules = {
    number: {
      value: true,
      message: t('studio.discount.register_rate_valid_msg')
    },
    gt: {
      value: 0,
      message: t('studio.group.collection.register_discount_rate_msg1')
    }
  };

  if (collectionDiscountType.value === Confirmation.YES) {
    return {
      ...rules,
      required: {
        value: true,
        message: t('studio.group.collection.register_discount_rate_msg1')
      }
    };
  }

  return rules;
});

const discountDaysRules = computed(() => {
  const rules = {
    number: {
      value: true,
      message: t('studio.discount.register_period_valid_msg')
    },
    gt: {
      value: 0,
      message: t('studio.discount.register_period_valid_msg')
    }
  };

  if (collectionDiscountType.value === Confirmation.YES) {
    return {
      ...rules,
      required: {
        value: true,
        message: t('studio.group.collection.register_discount_period_req_msg')
      }
    };
  }
  return rules;
});

const handleErrors = (
  date: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string,
  useEqual?: boolean
) => {
  const endDate = DateTime.fromJSDate(new Date(endedAt.value)).startOf('minute');

  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (!useEqual) {
    if (endDate < date) {
      errors.push(errorMessage);
    } else {
      const index = errors.findIndex((error: string) => error === errorMessage);

      if (index > -1) {
        errors.splice(index, 1);
      }
    }
    return;
  }

  if (endDate <= date) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const periodError = computed(() => {
  if (!endedAt.value) {
    return [];
  }

  const errors: string[] = [];

  const releaseDate = DateTime.fromJSDate(new Date(officialReleaseDate.value)).startOf('minute');

  if (endedAtError.value) {
    errors.push(endedAtError.value);

    return errors;
  }

  handleErrors(
    releaseDate,
    errors,
    'studio.event_period_settings.limit_sale_end_before_release_val_msg',
    true
  );

  setIsValid(errors);

  return errors;
});

const fetchPriceList = async () => {
  if (selectedProductList.value.length === 0) {
    return;
  }

  const result = await fetchProductsSellingPriceApi(
    selectedProductList.value.map((product: CollectionChild) => product.productNo)
  );

  if (result) {
    priceList.value = result.contents;
  }
};

const getExchangedPrice = (product: CollectionProductItem, nonDiscount?: boolean, useExchangeRate?: boolean) => {
  const discountRate = nonDiscount ? 0 : product.discountRate || 0;

  if (priceType.value === PRICE_STANDARD_VALUES.PAYMENT) {
    return (
      (product.priceList?.find(
        (price: PriceListType) => price.displayCurrencyCode === currency.value
      )?.salesPrice ?? 0) *
      (1 - discountRate / 100)
    );
  }

  if (useExchangeRate) {
    let exchangeRate = getExchangeInfo(currency.value)?.exchangeRate ?? 1;
    if (currency.value === CurrencyCode.Krw) {
      exchangeRate = 1;
    }

    return (
      (product.priceList?.find((price: PriceListType) => price.displayCurrencyCode === currency.value)
        ?.salesPrice ?? 0) * exchangeRate *
    (1 - discountRate / 100)
    );
  }

  return (
    (product.priceList?.find((price: PriceListType) => price.displayCurrencyCode === currency.value)
      ?.displayPrice ?? 0) *
    (1 - discountRate / 100)
  );
};

const getExchangedDiscountPrice = (originalPrice: number, product: CollectionProductItem) => {
  if (
    priceType.value === PRICE_STANDARD_VALUES.STORE ||
    currencyStandard.value === CurrencyCode.Krw
  ) {
    if (NON_DIGIT_CURRENCY_CODES.includes(currencyStandard.value as CurrencyCode)) {
      if (currencyStandard.value === CurrencyCode.Krw) {
        return getRoundedDownMoney(
          originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100
        );
      }
      if (currencyStandard.value === CurrencyCode.Vnd) {
        return getVndDigitsPrice(
          originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100
        );
      }
      return Math.floor(originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100);
    }
  }
  return truncateToDecimals(originalPrice - (originalPrice * (product.discountRate ?? 0)) / 100, 2);
};

const fetchDiscountList = async () => {
  const result = await fetchProductDiscountsApi(
    selectedProductList.value.map((product: CollectionChild) => product.productNo)
  );

  if (result) {
    discountList.value = result.contents;
  }
};

const init = async () => {
  fetchPriceList();
  fetchDiscountList();
  exchangeRates.value = await fetchCurrencyInfos(currencyCodes);
};

init();

watch(collectionPrice, () => {
  setPrice(collectionPrice.value);
});

watch(
  () => selectedProductList.value,
  async (newList: CollectionChild[], oldList: CollectionChild[]) => {
    if (newList.length > oldList.length) {
      fetchPriceList();
      fetchDiscountList();
    }
  }
);

watch(
  () => collectionPriceList.value,
  () => {
    emit('updateCollectionPriceList', collectionPriceList.value);
  }
);
</script>
