<template>
  <div class="flex flex-col gap-24">
    <div>
      <st-form-title
        :formTitle="$t('studio.group.collection.register_product_config')"
        count
        :currentCount="selectedProducts.length"
        :maxCount="MAX_PRODUCT_NUMBER"
      >
        <s-button
          size="sm"
          variant="outline"
          :isDisabled="selectedProducts.length === 0"
          @click="onCountryExposure"
        >
          {{ $t('studio.group.collection.register_product_config_nation_btn') }}
        </s-button>
      </st-form-title>
      <s-text as="p" role="cap1" class="text-placeholder">
        <safe-html tag="span" :html="$t('studio.group.collection.register_product_config_guide')" />
      </s-text>
      <div class="flex-1 mt-16">
        <st-search-layer
          :placeholder="
            $t('studio.prj_prod.this_prod.home_product_setting_prod_search_place_holder')
          "
          :searchLayerClass="'!w-full'"
          :isDisabled="!isMenuWritable || isDisabled"
          @search="handleSearch"
          @clear="handleSearch('', true)"
          @click="handleClick"
        >
          <div v-if="productOpts.length > 0">
            <checkbox-group
              v-model="selectedProductsId"
              name="childrenProductNos"
              :options="productOpts"
              size="sm"
              class="flex flex-col gap-4"
              :optionProps="{ align: 'middle', size: 'sm' }"
              @update:modelValue="setChildrenProductNos(selectedProductsId)"
            />
          </div>
          <div
            v-show="productOpts.length === 0"
            ref="collectionStSearchArea"
            class="flex justify-center items-center min-h-88"
          >
            <s-text as="p" role="text4" class="text-on-surface-elevation-2">
              {{
                hasSearched
                  ? $t('studio.group.collection.register_product_config_place_holder_msg1')
                  : $t('studio.group.collection.register_product_config_list_msg4')
              }}
            </s-text>
          </div>
        </st-search-layer>
      </div>
      <st-table
        tableClass="mt-12"
        :columns="columns"
        :data="selectedProducts"
        :paging="{ total: selectedProducts.length, show: selectedProducts.length > 10 }"
      >
        <template #col-1="{ row }">
          <st-td aLeft>
            <span class="font-medium"> {{ getProductTypeLabel(row) }}</span>
          </st-td>
        </template>
        <template #col-2="{ row }">
          <st-td aLeft>
            <span class="font-medium line-clamp-1">{{ row.productName }}</span>
          </st-td>
        </template>
        <template #col-3="{ row }">
          <st-td aLeft>
            <span class="font-medium">
              {{ $t(getCollectionChildStatusLabel(row.planStatus)) }}
            </span>

            <st-tooltip-info :content="$t(getDisplaySanctionText(row.restrictStatus))" />
          </st-td>
        </template>
        <template #col-4="{ row }">
          <st-td aLeft onlyButton>
            <s-button
              size="sm"
              variant="outline"
              :isDisabled="!isMenuWritable || isDisabled"
              @click="handleRemoveSelectedProducts(row.productNo)"
            >
              {{ $t('studio.group.collection.del_prod_from_list_btn') }}
            </s-button>
          </st-td>
        </template>
        <template #empty>
          <st-td colspan="5" aCenter>
            <s-text
              as="span"
              role="text3"
              class="flex items-center justify-center h-120 text-on-surface-elevation-4"
            >
              {{ $t('studio.group.collection.register_product_none_to_select') }}
            </s-text>
          </st-td>
        </template>
      </st-table>
      <st-error-message v-if="error && JSON.parse(error).message" :errorMessage="error" />
    </div>
    <div class="border-t border-solid border-border pt-24">
      <template v-if="selectedProducts.length > 1">
        <checkbox
          id="representative-product"
          v-model="representativeProduct"
          :disabled="!isMenuWritable || isDisabled"
          :options="{
            size: 'sm',
            align: 'middle'
          }"
        >
          {{ $t('studio.group.collection.register_product_config_check_box') }}
        </checkbox>
        <s-text as="p" role="cap1" class="text-placeholder mt-4">
          {{ $t('studio.group.collection.register_product_config_check_box_guide') }}
        </s-text>

        <div v-if="representativeProduct" class="flex p-16 gap-16">
          <div class="flex-1 relative">
            <dropdown
              v-model="selectedRepresentativeProduct"
              :options="collectionData"
              :placeholder="
                $t(
                  'studio.prj_prod.this_proj.this_prod.list_kebab_duplicate_pop_search_place_holder'
                )
              "
              :isDisabled="false"
              searchable
              :dropdownProps="{
                size: 'lg',
                offset: [0],
                placement: 'bottom',
                class: 'w-full',
                closeOnClick: false
              }"
              :dropdownPlaceholder="
                $t('studio.group.collection.register_product_config_place_holder')
              "
            >
              <template #dropdownItems>
                <template v-if="hasCollectionData">
                  <s-dropdown-item
                    v-for="item in collectionData"
                    :key="item.value"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </s-dropdown-item>
                </template>
              </template>
              <template #custom-empty>
                <li class="pointer-events-none pt-8" aria-disabled="true">
                  <button
                    class="stds-dropdown-list-item !px-0 relative flex items-center justify-center h-[7.5rem]"
                  >
                    {{ $t('studio.group.collection.register_product_config_place_holder_msg1') }}
                  </button>
                </li>
              </template>
            </dropdown>
          </div>
          <s-button variant="primary" size="md" @click="handleApplyProduct">
            {{ $t('studio.group.collection.register_product_config_select_apply_btn') }}
          </s-button>
        </div>
      </template>
    </div>
    <div>
      <st-form-title :formTitle="$t('studio.group.collection.register_product_page')" />
      <s-text as="p" role="cap1" class="text-placeholder mt-4">
        {{ $t('studio.group.collection.register_product_page_guide') }}
      </s-text>
      <s-button
        size="md"
        variant="primary"
        class="mt-8"
        :isDisabled="!isMenuWritable || isDisabled"
        @click="handleOpenProductPage"
      >
        {{ $t('studio.group.collection.register_product_page_edit_btn') }}
      </s-button>
      <st-error-message
        v-if="productPageError && JSON.parse(productPageError).message"
        :errorMessage="productPageError"
      />
      <st-error-message
        v-if="productPageMissingFieldsError && JSON.parse(productPageMissingFieldsError).message"
        :errorMessage="productPageMissingFieldsError"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash';
import { storeToRefs } from 'pinia';
import { useFieldValue, useSetFieldTouched, useSetFieldValue } from 'vee-validate';
import { computed, defineModel, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import {
  fetchCollectionChildrenApi,
  fetchCollectionRepresentativeProductApi
} from '@/apis/collection.api';
import {
  createSimpleProductPageApi,
  fetchProductPageDetailApi,
  modifyProductPageDetailApi
} from '@/apis/product-page.api';
import CollectionProductPageDialog from '@/components/collection/collection-product-page-dialog.vue';
import CountryExposureDialog from '@/components/collection/country-exposure-dialog.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import StSearchLayer from '@/components/common/st-search-layer.vue';
import StTooltipInfo from '@/components/common/st-tooltip-info.vue';
import StTable from '@/components/common/table/st-table.vue';
import StTd from '@/components/common/table/st-td.vue';
import Checkbox from '@/components/validation/checkbox.vue';
import CheckboxGroup from '@/components/validation/checkbox-group.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { STATUS_CODE } from '@/constants/error.const';
import { DEFAULT_LOCALE, DEFAULT_LOCALE_UPPERCASE } from '@/constants/locale.const';
import { ROLE_ID } from '@/constants/permission.const';
import { COLLECTION_PRODUCT_TYPE, PRODUCT_TYPE } from '@/constants/products.const';
import { COLLECTION_MODIFY_URL, PRODUCT_PAGES_PAGE_URL } from '@/constants/url.const';
import { PRODUCT_PAGE_RESOURCES_RESPONSE } from '@/enums/product-page.enum';
import { RuleNames } from '@/enums/validation.enum';
import {
  featureMapper,
  imageListResponseMapper,
  productPageMapper,
  productPageResponseMapper
} from '@/mappers/product-page.mapper';
import { useAppStore } from '@/stores/app.store';
import { useCollectionStore } from '@/stores/collection.store';
import { useProductPageStore } from '@/stores/product-page.store';
import { useUserStore } from '@/stores/user.store';
import type { CollectionChild } from '@/types/collection/collection.type';
import type { CollectionRepresentativeResponse } from '@/types/collection/collection-response.type';
import type { ErrorResponse } from '@/types/common/common.type';
import type { FormOption } from '@/types/common/form.type';
import type {
  ProductLinkType,
  ProductPageFormType,
  ProductPageLanguage
} from '@/types/product-page.type';
import type { ProductPageDetailResponse } from '@/types/product-page/product-page-response.type';
import type { Column, ColumnSorting } from '@/types/table.type';
import { redirectTo } from '@/utils/common.util';
import { getDisplaySanctionText } from '@/utils/product.util';
import { hasInputAllRequiredFields } from '@/utils/product-page.util';
import { capitalizeFirstLetter } from '@/utils/string.util';
import { clientSideSort } from '@/utils/table.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  isSubmitted: boolean;
  isDisabled?: boolean;
}>();

const MAX_PRODUCT_NUMBER = 100;

const { checkProductPermission } = useApp();
const currentPageId = defineModel<string>({ required: true });

const { isMenuWritable } = storeToRefs(useAppStore());

const userStore = useUserStore();
const { selectedGroupId, selectedGroupInfo } = storeToRefs(userStore);

const collectionStore = useCollectionStore();
const { selectedProductList } = storeToRefs(collectionStore);

const productPageStore = useProductPageStore();
const { setProductDescription } = productPageStore;
const { showOnlyRequiredFields } = storeToRefs(productPageStore);

const { isSubmitted } = toRefs(props);

const selectedProductsId = ref<number[]>([]);
const searchText = ref<string>('');
const productOpts = ref<FormOption<number>[]>([]);
const totalElements = ref<number>(0);
const isEmptyResult = ref<boolean>(false);
const representativeProduct = ref<boolean>(false);
const selectedRepresentativeProduct = ref<number>();
const hasCollectionData = ref<boolean>(true);
const collectionStSearchArea = ref<HTMLElement | null>(null);
const hasSearched = ref<boolean>(false);
const showErrorProductPage = ref<boolean>(false);
const { t, locale } = useI18n();
const products = ref<CollectionChild[]>([]);
const isInit = ref<boolean>(false);
const openPage = ref<boolean>(false);

const showError = computed(() => isSubmitted.value);

const productName = useFieldValue<string>('basicProductInfos.productName');
const childrenProductNos = useFieldValue<number[]>('childrenProductNos');
const childProducts = useFieldValue<CollectionChild[]>('childProducts');
const productPageDetails = useFieldValue<ProductPageDetailResponse>('productPageDetails');

const setChildrenProductNos = useSetFieldValue('childrenProductNos');
const setPageInfo = useSetFieldValue('pageInfo');
const setProductPageDetails = useSetFieldValue('productPageDetails');

const error = computed(() => {
  if (childrenProductNos.value?.length < 2 && showError.value) {
    return generateErrorMsg(
      'studio.group.collection.register_product_config_list_msg1',
      RuleNames.REQUIRED
    );
  }

  return undefined;
});

const setTouch = useSetFieldTouched('productName');

const route = useRoute();
const { mode } = route.params;
const { productNo } = route.query;

const selectedProducts = ref<CollectionChild[]>([]);

const collectionData = computed(() => {
  return selectedProducts.value.map((product: CollectionChild) => ({
    value: product.productNo,
    label: `(${product.productNo}) ${product.productName}`
  }));
});

const productPageError = computed(() => {
  if (!productName.value) {
    return showErrorProductPage.value
      ? generateErrorMsg(
        'studio.group.collection.register_basic_info_name_msg1',
        RuleNames.REQUIRED
      )
      : undefined;
  }

  return undefined;
});

const productPageMissingFieldsError = computed(() => {
  if (isEmpty(productPageDetails.value)) {
    return showError.value
      ? generateErrorMsg(
        'studio.group.collection.register_product_page_req_info_val',
        RuleNames.REQUIRED
      )
      : undefined;
  }

  const allRequiredFieldsFilled = hasInputAllRequiredFields(
    productPageResponseMapper(
      productPageDetails.value,
      selectedGroupInfo?.value?.languageCd.replace('-', '_').toUpperCase() ||
        DEFAULT_LOCALE_UPPERCASE
    ),
    true,
    selectedGroupInfo?.value?.languageCd,
    true
  );
  if (!allRequiredFieldsFilled && showError.value) {
    return generateErrorMsg(
      'studio.group.collection.register_product_page_req_info_val',
      RuleNames.REQUIRED
    );
  }
  return undefined;
});

const columns: Column[] = [
  {
    title: 'studio.group.collection.register_product_config_list_no',
    dataIndex: 'productNo',
    width: '8.8rem',
    align: 'left',
    sortKey: 'productNo',
    titleTooltip: 'studio.prj_prod.this_prod.home_product_setting_higher_game_no',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortSelectedProducts(sortValue, sortKey)
  },
  {
    title: 'studio.group.collection.register_product_config_list_type',
    dataIndex: 'productType',
    width: '9.2rem',
    align: 'left'
  },
  {
    title: 'studio.group.collection.register_product_config_list_name',
    dataIndex: 'productName',
    width: 'auto',
    align: 'left',
    sortKey: 'productName',
    onSort: ({ sortValue, sortKey }: ColumnSorting) => onSortSelectedProducts(sortValue, sortKey)
  },
  {
    title: 'studio.group.collection.register_product_config_list_status',
    dataIndex: 'productStatus',
    width: '12rem',
    align: 'left'
  },
  { title: '', width: '6.4rem', align: 'left' }
];

const onSortSelectedProducts = (sortValue: number, sortKey: string) => {
  selectedProducts.value = clientSideSort(
    [...selectedProducts.value],
    sortKey,
    sortValue,
    locale.value
  );
};

const getProductTypeLabel = (product: CollectionChild) => {
  return `${capitalizeFirstLetter(product.productType)}${
    product.productDetailType === PRODUCT_TYPE.DLC ? ` ${product.productDetailType}` : ''
  }`;
};

const onCountryExposure = async () => {
  await showDialog({
    component: shallowRef(CountryExposureDialog),
    props: {
      selectedProducts
    }
  });
};

const handleOpenProductPage = async () => {
  if (
    !(await checkProductPermission({
      productId: productNo?.toString(),
      roleId: ROLE_ID.PRODUCT_STORE_PAGE
    }))
  ) {
    await showAlert({ content: t('studio.common.popup_case_g_not_view') });
    return;
  }

  openPage.value = true;
  showErrorProductPage.value = true;
  showOnlyRequiredFields.value = true;
  setProductDescription('');
  setTouch(true);
  if (productPageError.value) {
    return;
  }

  if (!currentPageId.value) {
    const res = await createSimpleProductPageApi({
      pageName: productName.value,
      productNo: Number(productNo)
    });

    if (!res) {
      return;
    }

    const productPage = await showDialog({
      component: shallowRef(CollectionProductPageDialog),
      props: {
        productName: productName.value,
        returnSource: `${COLLECTION_MODIFY_URL}?productNo=${productNo}`,
        pageId: res.pageId,
        childProductNos: selectedProductsId.value
      }
    });

    currentPageId.value = res.pageId;
    setPageInfo(productPage);
    return;
  }

  const productPage = await showDialog<ProductPageFormType>({
    component: shallowRef(CollectionProductPageDialog),
    props: {
      productName: productName.value,
      returnSource: `${COLLECTION_MODIFY_URL}?productNo=${productNo}`,
      pageId: currentPageId.value,
      childProductNos: selectedProductsId.value
    }
  });

  openPage.value = false;

  if (!productPage) {
    return;
  }

  const page = await fetchProductPageDetailApi(currentPageId.value);
  setProductPageDetails(page);
  setPageInfo(productPage);
};

const handleSearch = async (v: string, isClear?: boolean) => {
  searchText.value = v;

  // const result = await searchProductsApi({ q: searchText.value, groupId: selectedGroupId.value });
  const result = await fetchCollectionChildrenApi({
    q: searchText.value,
    groupId: selectedGroupId.value
  });

  if (result?.children) {
    if (result.children.length === 0) {
      isEmptyResult.value = true;
    }
    totalElements.value = result.children.length;

    const notIncludedProducts = result.children.filter(
      (product: CollectionChild) =>
        !selectedProductsId.value.includes(product.productNo) &&
        !products.value.find((p: CollectionChild) => p.productNo === product.productNo)
    );

    products.value = products.value.concat(notIncludedProducts);

    if (selectedProductsId.value.length >= MAX_PRODUCT_NUMBER) {
      productOpts.value = result.children.map((r: CollectionChild) => ({
        value: r.productNo,
        label: `(${r.productNo}) ${r.productName}`,
        isDisabled: !selectedProductsId.value.includes(r.productNo)
      }));
    } else {
      productOpts.value = result.children.map((r: CollectionChild) => ({
        value: r.productNo,
        label: `(${r.productNo}) ${r.productName}`,
        isDisabled: false
      }));
    }
  }

  if (isClear) {
    hasSearched.value = false;
  } else {
    hasSearched.value = true;
  }
};

const handleClick = () => {
  if (childProducts.value.length > 0 || selectedProductsId.value.length > 0) {
    return;
  }

  handleSearch('');
};

const handleProductPageRepresentative = async (
  pageId: string,
  representativeProductInfo: CollectionRepresentativeResponse
) => {
  let productPageId: string = pageId;
  if (!pageId) {
    const result = await createSimpleProductPageApi({
      pageName: productName.value,
      productNo: Number(productNo)
    });

    if (!result) {
      return;
    }

    productPageId = result.pageId;
    currentPageId.value = result.pageId;
  }

  const currentPageDetail = await fetchProductPageDetailApi(productPageId);

  if (!currentPageDetail) {
    return;
  }

  let request = productPageResponseMapper(
    currentPageDetail,
    selectedGroupInfo.value?.languageCd.replace('-', '_').toUpperCase() || DEFAULT_LOCALE_UPPERCASE
  );

  const defaultLangLower = (selectedGroupInfo.value?.languageCd || 'ko')
    .toLowerCase()
    .replace('_', '-');
  const defaultLangUpper = (selectedGroupInfo.value?.languageCd || 'ko')
    .replace('-', '_')
    .toUpperCase();

  request = {
    ...request,
    oneLineIntroduction: representativeProductInfo.shortPieces[defaultLangUpper],
    image1x1: imageListResponseMapper(
      representativeProductInfo.resources?.[PRODUCT_PAGE_RESOURCES_RESPONSE.COVER_TITLE],
      defaultLangLower
    ),
    image16x9: imageListResponseMapper(
      representativeProductInfo.resources?.[
        PRODUCT_PAGE_RESOURCES_RESPONSE.COVER_TITLE_LIST_DEFAULT
      ],
      defaultLangLower
    ),
    exhibitionVideosAndImages: imageListResponseMapper(
      representativeProductInfo.resources?.[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_SCREENSHOT]
    ),
    exhibitionVideosAndImagesSorted: imageListResponseMapper(
      representativeProductInfo.resources?.[PRODUCT_PAGE_RESOURCES_RESPONSE.INTRODUCE_SCREENSHOT]
    ),
    translatedContents: request.translatedContents?.map(
      (content: ProductPageLanguage<ProductLinkType[]>) => {
        let lang = content.langCode.replace('-', '_').toUpperCase();
        if (lang === 'ZH_CN') {
          lang = 'ZHCN';
        }
        if (lang === 'ZH_TW') {
          lang = 'ZHTW';
        }
        return {
          ...content,
          oneLineIntroduction: representativeProductInfo.shortPieces[lang]
        };
      }
    ),
    supportedLanguages: Object.keys(request || {}).map((lang: string) => {
      if (lang === 'zh-tw') {
        return 'ZH_TW';
      }
      if (lang === 'zh-cn') {
        return 'ZH_CN';
      }
      return lang.toUpperCase();
    }),
    supportedLanguageSettings: Object.keys(representativeProductInfo.supportLanguages || {}).map(
      (lang: string) => ({
        lang: (lang || 'KO').toUpperCase().replace('-', '_'),
        settings: Object.keys(representativeProductInfo.supportLanguages[lang])
          .filter(
            (key: string) =>
              (representativeProductInfo.supportLanguages[lang] as { [key: string]: boolean })[key]
          )
          .map((key: string) => featureMapper(key)) as string[]
      })
    )
  };

  await modifyProductPageDetailApi(
    productPageId,
    productPageMapper(
      request,
      selectedGroupInfo.value?.languageCd.replace('-', '_').toUpperCase() || DEFAULT_LOCALE
    )
  );
};

const handleApplyProduct = async () => {
  const result = await showConfirm({
    content: t('studio.group.collection.apply_prod_info_to_collection_popup'),
    confirmLabel: t('studio.common.popup_case_apply_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (!result) {
    return;
  }

  const representativeProduct = products.value.find(
    (product: CollectionChild) => product.productNo === Number(selectedRepresentativeProduct.value)
  );
  if (!representativeProduct) {
    return;
  }

  try {
    const infoResult = await fetchCollectionRepresentativeProductApi(
      representativeProduct.productNo.toString()
    );

    if (infoResult) {
      handleProductPageRepresentative(currentPageId.value, infoResult);
    }
  } catch (err) {
    const error = err as ErrorResponse;
    if (error.statusCode === STATUS_CODE.PRODUCT_PAGE_NOT_FOUND) {
      const result = await showConfirm({
        content: t('studio.group.collection.register_product_config_select_none_available_msg'),
        confirmLabel: t('studio.group.collection.register_product_page_list_dir_btn'),
        cancelLabel: t('studio.common.popup_case_cancel_btn'),
        cancelVariant: 'outline'
      });

      if (!result) {
        return;
      }

      redirectTo(PRODUCT_PAGES_PAGE_URL, {
        projectId: representativeProduct.projectId,
        productId: representativeProduct.productNo,
        open: {
          target: '_blank'
        }
      });
    }
  }
};

const handleRemoveSelectedProducts = (productNo: number) => {
  selectedProductsId.value = selectedProductsId.value.filter((id: number) => id !== productNo);

  setChildrenProductNos(selectedProductsId.value);
};

const getCollectionChildStatusLabel = (planStatus: string) => {
  switch (planStatus) {
    case 'NONE':
      return '-';
    case 'READY':
      return 'studio.prj_prod.this_prod.stt_product_page_upcoming';
    case 'PAGE_OPEN':
      return 'studio.prj_prod.this_prod.stt_product_page_released';
    case 'PRE_PURCHASE':
      return 'studio.prj_prod.this_prod.stt_pre_order_period';
    case 'RELEASE':
      return 'studio.prj_prod.this_prod.stt_official_released';
    default:
      return '-';
  }
};

watch(selectedProducts, () => {
  selectedProductList.value = selectedProducts.value;
});

watch(
  () => selectedProductsId.value,
  () => {
    selectedProducts.value = products.value.filter((product: CollectionChild) => {
      return selectedProductsId.value.includes(product.productNo);
    });

    if (selectedProducts.value.length >= MAX_PRODUCT_NUMBER) {
      productOpts.value = productOpts.value.map((product: FormOption<number>) => {
        if (selectedProductsId.value.includes(product.value)) {
          return {
            ...product
          };
        }

        return {
          ...product,
          isDisabled: true
        };
      });

      return;
    }

    productOpts.value = productOpts.value.map((product: FormOption<number>) => {
      return {
        ...product,
        isDisabled: false
      };
    });
  },
  {
    deep: true
  }
);

watch(
  () => childProducts.value,
  () => {
    products.value = childProducts.value;
    selectedProductsId.value = childProducts.value.map(
      (product: CollectionChild) => product.productNo
    );
    if (childProducts.value.length > 0 && isMenuWritable.value && !props.isDisabled) {
      handleSearch('');
    }
  },
  {
    once: true
  }
);

watch(
  () => products.value,
  () => {
    if (mode === MODE.EDIT && !isInit.value) {
      selectedProducts.value = childProducts.value;
      isInit.value = true;
    }
    selectedProducts.value = products.value.filter((product: CollectionChild) =>
      selectedProductsId.value.includes(product.productNo)
    );
  }
);

watch(collectionStSearchArea, () => {
  if (collectionStSearchArea.value === null) {
    hasSearched.value = false;
  }
  setChildrenProductNos(selectedProductsId.value);
});

watch(representativeProduct, () => {
  if (representativeProduct.value) {
    // selectedRepresentativeProduct.value = selectedProductsId.value[0].toString();
    const gameList: CollectionChild[] = selectedProducts.value.filter(
      (product: CollectionChild) => product.productType === COLLECTION_PRODUCT_TYPE.GAME
    );

    if (gameList.length > 0) {
      selectedRepresentativeProduct.value = Math.min(
        ...gameList.map((g: CollectionChild) => g.productNo)
      );
    }
    selectedRepresentativeProduct.value = Math.min(...selectedProductsId.value);
  }
});

watch(selectedProductsId, () => {
  if (
    representativeProduct.value &&
    selectedRepresentativeProduct.value &&
    !selectedProductsId.value.includes(selectedRepresentativeProduct.value)
  ) {
    const gameList: CollectionChild[] = selectedProducts.value.filter(
      (product: CollectionChild) => product.productType === COLLECTION_PRODUCT_TYPE.GAME
    );

    if (gameList.length > 0) {
      selectedRepresentativeProduct.value = Math.min(
        ...gameList.map((g: CollectionChild) => g.productNo)
      );
    }
    selectedRepresentativeProduct.value = Math.min(...selectedProductsId.value);
  }
});
</script>
