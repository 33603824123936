<template>
  <div class="flex flex-col gap-24">
    <div class="flex flex-col gap-8">
      <st-form-title
        :formTitle="$t('studio.group.collection.register_plan_open_date')"
        :utcTimezone="`(${utcTimezone})`"
      >
        <s-button
          size="xs"
          variant="outline"
          @click="showMajorCitiesUtcDialog(productPageOpenedAt)"
        >
          {{ $t('studio.group.collection.register_plan_utc_view_btn') }}
        </s-button>
      </st-form-title>
      <div>
        <div class="flex flex-col gap-16 bg-surface-elevation-2 rounded-xl py-16">
          <!-- product page launch -->
          <datetime-picker
            :isDisabled="disablePageOpenedAt"
            :rules="pageOpenedAtRules"
            name="productPlan.page.pageOpenedAt"
            containerClass="!py-0 !bg-transparent"
            :disabledDates="disabledDates"
            noShowError
          />
        </div>
        <template v-if="!isPageOpenedAtErrorsDisabled">
          <st-error-message
            v-for="(error, index) in pageOpenedAtErrors"
            :key="index"
            class="mt-8"
            :errorMessage="error"
          />
        </template>
      </div>
    </div>

    <div>
      <st-form-title :formTitle="$t('studio.group.collection.register_plan_pre_order')" />
      <s-text as="p" role="cap1" class="text-placeholder">
        {{ $t('studio.group.collection.register_plan_pre_order_guide') }}
      </s-text>
      <radio-group
        name="productPlan.prePurchase.prePurchaseYn"
        :options="prepurchaseSettingOpts"
        :disabled="!isMenuWritable || isDisabled || disableCondition"
        :optionProps="{
          size: 'sm',
          align: 'middle'
        }"
        class="flex-1 flex items-center gap-24 mt-8 mb-16"
      />
    </div>

    <div v-show="prePurchaseYn === Confirmation.YES" class="flex flex-col gap-8">
      <st-form-title
        :formTitle="t('studio.group.collection.register_plan_pre_order_period')"
        :utcTimezone="`(${utcTimezone})`"
      />
      <div class="flex flex-col gap-16 bg-surface-elevation-2 rounded-xl py-16">
        <!-- pre-start -->
        <datetime-picker
          :label="$t('studio.group.collection.register_plan_pre_order_period_start')"
          name="productPlan.prePurchase.prePurchaseSchedule.startedAt"
          :isDisabled="!isMenuWritable || isDisabled || disableCondition"
          :rules="startedAtRules"
          containerClass="!py-0 !bg-transparent"
          :disabledDates="disabledPreOrderPeriodStartDates"
          noShowError
        />
        <!-- pre-end -->
        <datetime-picker
          :label="$t('studio.group.collection.register_plan_pre_order_period_end')"
          name="productPlan.prePurchase.prePurchaseSchedule.endedAt"
          :isDisabled="!isMenuWritable || isDisabled || disableCondition"
          :rules="endedAtRules"
          containerClass="!py-0 !bg-transparent"
          :disabledDates="disabledPreOrderPeriodEndDates"
        />
      </div>
      <template v-if="!isDateErrorsDisabled">
        <st-error-message
          v-for="(error, index) in dateErrors"
          :key="index"
          class="mt-8"
          :errorMessage="error"
        />
      </template>
    </div>

    <div>
      <st-form-title
        :formTitle="$t('studio.group.collection.register_plan_official_release_date_option')"
      />
      <dropdown
        name="productPlan.release.releaseTimeFormat"
        containerClass="w-360 relative mt-8"
        :options="releaseDateDisplayOpts"
        :disabled="disableReleasedAt"
        :dropdownProps="{
          size: 'lg',
          class: 'w-full shrink-0',
          placement: 'bottom',
          distance: 0,
          offset: [0, 0]
        }"
      />
    </div>

    <div class="flex flex-col gap-4">
      <st-form-title
        :formTitle="$t('studio.group.collection.register_plan_official_release_date')"
        :utcTimezone="`(${utcTimezone})`"
      >
        <s-button size="xs" variant="outline" @click="showMajorCitiesUtcDialog(releasedAt)">
          {{ $t('studio.group.collection.register_plan_utc_view_btn') }}
        </s-button>
      </st-form-title>
      <s-text as="p" role="cap1" class="text-placeholder">
        <safe-html
          tag="span"
          :html="$t('studio.group.collection.register_plan_official_release_date_guide')"
        />
      </s-text>
      <div>
        <div class="flex flex-col gap-16 rounded-xl">
          <!-- general availability -->
          <datetime-picker
            name="productPlan.release.releasedAt"
            :isDisabled="disableReleasedAt"
            :rules="releasedAtRules"
            containerClass="bg-surface-elevation-2"
            :disabledDates="disabledReleasedDates"
            noShowError
          />
        </div>
        <template v-if="!isReleasedAtErrorsDisabled">
          <st-error-message
            v-for="(error, index) in releasedAtErrors"
            :key="index"
            class="mt-8"
            :errorMessage="error"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldError, useFieldValue, useSetFieldValue } from 'vee-validate';
import { computed, ref, shallowRef, toRefs, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import StErrorMessage from '@/components/common/st-error-message.vue';
import StFormTitle from '@/components/common/st-form-title.vue';
import MajorCitiesUtcDialog from '@/components/release-plan/dialog/major-cities-utc-dialog.vue';
import DatetimePicker from '@/components/validation/datetime-picker.vue';
import Dropdown from '@/components/validation/dropdown.vue';
import RadioGroup from '@/components/validation/radio-group.vue';
import { showDialog } from '@/composables/useDialog';
import { PLAN_STATUS } from '@/constants/products.const';
import {
  RELEASE_PLAN_LIMITED_SALES_STATUS,
  RELEASE_TIME_FORMAT
} from '@/constants/release-plan.const';
import { Confirmation } from '@/enums/common.enum';
import { useAppStore } from '@/stores/app.store';
import type { FormOption } from '@/types/common/form.type';
import { getUtcTimezone } from '@/utils/date.util';
import { generateErrorMsg } from '@/utils/validation.util';

const props = defineProps<{
  isDisabled?: boolean;
  isSubmitted?: boolean;
}>();

const { isSubmitted } = toRefs(props);

const utcTimezone = getUtcTimezone();

const { t } = useI18n();

const { isMenuWritable } = storeToRefs(useAppStore());

const today = DateTime.local().startOf('day').toJSDate();
const disabledDates = {
  to: today
};

const disabledPreOrderPeriodStartDates = computed(() => {
  return {
    to: openedAtDate.value.startOf('day').toJSDate()
  };
});

const disabledPreOrderPeriodEndDates = computed(() => {
  return {
    to: preStartedAtDate.value.startOf('day').toJSDate()
  };
});

const disabledReleasedDates = computed(() => {
  if (prePurchaseYn.value === Confirmation.YES) {
    return {
      to: preEndedAtDate.value.startOf('day').toJSDate()
    };
  }

  return {
    to: openedAtDate.value.startOf('day').toJSDate()
  };
});

const startedAt = useFieldValue<number>('productPlan.prePurchase.prePurchaseSchedule.startedAt');
const endedAt = useFieldValue<number>('productPlan.prePurchase.prePurchaseSchedule.endedAt');
const productPageOpenedAt = useFieldValue<number>('productPlan.page.pageOpenedAt');
const releasedAt = useFieldValue<number>('productPlan.release.releasedAt');
const saleLimitEndedAt = useFieldValue<number>('productPlan.release.sale.saleLimitEndedAt');
const saleLimitType = useFieldValue<string>('productPlan.release.sale.saleLimitType');
const prePurchaseYn = useFieldValue<string>('productPlan.prePurchase.prePurchaseYn');
const planStatus = useFieldValue<string>('planStatus');

const setIsValid = useSetFieldValue<boolean>('isValid');

const productPageOpenedAtError = useFieldError('productPlan.page.pageOpenedAt');
const startedAtError = useFieldError('productPlan.prePurchase.prePurchaseSchedule.startedAt');
const releasedAtError = useFieldError('productPlan.release.releasedAt');

const saleSettingErrors = useFieldValue<string[]>('saleSettingErrors');

const startedAtRules = computed(() => {
  if (!isMenuWritable.value || props.isDisabled || disableCondition.value) {
    return {};
  }
  if (prePurchaseYn.value === Confirmation.YES) {
    return {
      required: true,
      less_than_current_time: {
        value: true,
        message: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
      }
    };
  }
  return {
    less_than_current_time: {
      value: true,
      message: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
    }
  };
});

const endedAtRules = computed(() => {
  if (prePurchaseYn.value === Confirmation.YES) {
    return {
      required: true
    };
  }
  return {};
});

const disableCondition = computed(() => {
  return planStatus.value === PLAN_STATUS.PRE_PURCHASE || planStatus.value === PLAN_STATUS.RELEASE;
});
const disablePageOpenedAt = computed(() => {
  return (
    !isMenuWritable ||
    props.isDisabled ||
    (planStatus.value !== PLAN_STATUS.NONE && planStatus.value !== PLAN_STATUS.READY)
  );
});
const disableReleasedAt = computed(() => {
  return !isMenuWritable || props.isDisabled || planStatus.value === PLAN_STATUS.RELEASE;
});

const pageOpenedAtRules = computed(() => {
  if (disablePageOpenedAt.value) {
    return {};
  }

  return {
    less_than_current_time: {
      value: true,
      message: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
    }
  };
});

const releasedAtRules = computed(() => {
  if (disableReleasedAt.value) {
    return {};
  }

  return {
    required: {
      value: true,
      message: t('studio.group.collection.register_plan_official_release_date_msg1')
    },
    less_than_current_time: {
      value: true,
      message: t('studio.calendar_event_period.val_msg_start_time_before_current_time')
    }
  };
});

const prepurchaseSettingOpts = ref<FormOption[]>([
  { label: t('studio.group.collection.register_plan_pre_order_y'), value: Confirmation.YES },
  { label: t('studio.group.collection.register_plan_pre_order_n'), value: Confirmation.NO }
]);

const releaseDateDisplayOpts = computed(() => {
  const releaseDate = releasedAt.value
    ? DateTime.fromMillis(new Date(releasedAt.value).getTime())
    : DateTime.fromMillis(new Date().getTime());
  return [
    {
      label: t('studio.group.collection.register_plan_official_release_date_option_def_value'),
      value: RELEASE_TIME_FORMAT.COMING_SOON
    },
    { label: releaseDate.toFormat('yyyy.LL.dd'), value: RELEASE_TIME_FORMAT.YEAR_MONTH_DAY },
    { label: releaseDate.toFormat('yyyy.LL'), value: RELEASE_TIME_FORMAT.YEAR_MONTH },
    {
      label: `${t(
        'studio.prj_prod.this_prod.release_details_plan_release_schedule_setting_release_option_dbox_opt4_quarter',
        { quarter: releaseDate.quarter, relYear: releaseDate.toFormat('yyyy') }
      )}`,
      value: RELEASE_TIME_FORMAT.YEAR_QUARTER
    },
    { label: releaseDate.toFormat('yyyy'), value: RELEASE_TIME_FORMAT.YEAR }
  ];
});

const showMajorCitiesUtcDialog = async (rootDate: number) => {
  const props = {
    rootDate: new Date(rootDate) || new Date()
  };
  await showDialog({
    component: shallowRef(MajorCitiesUtcDialog),
    props: {
      ...props
    }
  });
};

const openedAtDate = computed(() =>
  DateTime.fromJSDate(new Date(productPageOpenedAt.value)).startOf('minute')
);
const releaseDate = computed(() =>
  DateTime.fromJSDate(new Date(releasedAt.value)).startOf('minute')
);
const preStartedAtDate = computed(() =>
  DateTime.fromJSDate(new Date(startedAt.value)).startOf('minute')
);
const preEndedAtDate = computed(() =>
  DateTime.fromJSDate(new Date(endedAt.value)).startOf('minute')
);
const nowDate = computed(() => DateTime.fromJSDate(new Date()).startOf('minute'));

const isDateErrorsDisabled = computed(() => {
  return !isMenuWritable.value || props.isDisabled || disableCondition.value;
});

const dateErrors = computed(() => {
  if (isDateErrorsDisabled.value) {
    return [];
  }

  return Array.from(new Set(preStartedAtErrors.value.concat(preEndedAtErrors.value))).slice(0, 1);
});

const handleEqualErrors = (
  firstDate: DateTime<true> | DateTime<false>,
  secondDate: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string
) => {
  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (firstDate.toMillis() === secondDate.toMillis()) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const handleGreaterOrEqualErrors = (
  firstDate: DateTime<true> | DateTime<false>,
  secondDate: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string
) => {
  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (firstDate >= secondDate) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const handleLessOrEqualErrors = (
  firstDate: DateTime<true> | DateTime<false>,
  secondDate: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string
) => {
  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (firstDate <= secondDate) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const handleLessThanErrors = (
  firstDate: DateTime<true> | DateTime<false>,
  secondDate: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string
) => {
  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (firstDate < secondDate) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const handleGreaterThanErrors = (
  firstDate: DateTime<true> | DateTime<false>,
  secondDate: DateTime<true> | DateTime<false>,
  errors: string[],
  message: string
) => {
  const errorMessage = generateErrorMsg(t(`${message}`));
  if (errors.includes(errorMessage)) {
    return;
  }
  if (firstDate > secondDate) {
    errors.push(errorMessage);
  } else {
    const index = errors.findIndex((error: string) => error === errorMessage);

    if (index > -1) {
      errors.splice(index, 1);
    }
  }
};

const isPageOpenedAtErrorsDisabled = computed(() => {
  return (
    !isMenuWritable.value ||
    props.isDisabled ||
    (planStatus.value !== PLAN_STATUS.NONE && planStatus.value !== PLAN_STATUS.READY)
  );
});

const pageOpenedAtErrors = computed(() => {
  const errors: string[] = [];

  if (isPageOpenedAtErrorsDisabled.value) {
    return [];
  }

  if (productPageOpenedAtError.value) {
    errors.push(productPageOpenedAtError.value);

    return errors;
  }

  handleLessOrEqualErrors(
    openedAtDate.value,
    nowDate.value,
    errors,
    'studio.calendar_event_period.val_msg_start_time_before_current_time'
  );

  return errors.slice(0, 1);
});

const isReleasedAtErrorsDisabled = computed(() => {
  return !isMenuWritable.value || props.isDisabled || planStatus.value === PLAN_STATUS.RELEASE;
});

const releasedAtErrors = computed(() => {
  const errors: string[] = [];
  if (!isSubmitted.value) {
    return [];
  }

  if (isReleasedAtErrorsDisabled.value) {
    return [];
  }

  if (releasedAtError.value) {
    errors.push(releasedAtError.value);

    return errors;
  }

  handleLessThanErrors(
    releaseDate.value,
    openedAtDate.value,
    errors,
    'studio.event_period_settings.case_pp_open_after_official_release'
  );

  return errors.slice(0, 1);
});

const preStartedAtErrors = computed(() => {
  const errors: string[] = [];

  if (startedAtError.value) {
    errors.push(startedAtError.value);

    return errors;
  }

  if (prePurchaseYn.value === Confirmation.YES) {
    handleEqualErrors(
      releaseDate.value,
      openedAtDate.value,
      errors,
      'studio.release_info_settings.cannot_set_pre_purchase_msg'
    );

    handleGreaterOrEqualErrors(
      preStartedAtDate.value,
      preEndedAtDate.value,
      errors,
      'studio.release_details.period_settings.end_before_start_val_msg1'
    );

    handleLessThanErrors(
      preStartedAtDate.value,
      openedAtDate.value,
      errors,
      'studio.release_details.period_settings.start_date_before_open_val_msg2'
    );

    return errors.slice(0, 1);
  }

  return errors;
});

const preEndedAtErrors = computed(() => {
  const errors: string[] = [];

  if (prePurchaseYn.value === Confirmation.YES) {
    handleLessOrEqualErrors(
      preEndedAtDate.value,
      preStartedAtDate.value,
      errors,
      'studio.release_details.period_settings.end_before_start_val_msg1'
    );
    handleGreaterThanErrors(
      preEndedAtDate.value,
      releaseDate.value,
      errors,
      'studio.release_details.period_settings.end_date_after_official_release_val_msg4'
    );
    if (saleLimitType.value === RELEASE_PLAN_LIMITED_SALES_STATUS.DATE && saleLimitEndedAt.value) {
      handleGreaterOrEqualErrors(
        openedAtDate.value,
        releaseDate.value,
        errors,
        'studio.event_period_settings.case12_pre_purchase_end_after_limited_sale_end'
      );
    }

    return errors.slice(0, 1);
  }

  return errors;
});

watch(
  () => [
    pageOpenedAtErrors.value,
    releasedAtErrors.value,
    preStartedAtErrors.value,
    preEndedAtErrors.value,
    saleSettingErrors.value
  ],
  (allErrors: string[][]) => {
    if (allErrors.some((error: string[]) => error.length > 0)) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }
);
</script>
