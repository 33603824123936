<template>
  <div class="max-w-[96rem] mx-auto">
    <st-title
      :navItems="[
        {
          name: $t('studio.group.collection.title'),
          url: 'collections'
        },
        {
          name: $t('studio.group.collection.register_title')
        }
      ]"
      :title="
        mode === MODE.CREATE
          ? $t('studio.group.collection.register_title')
          : $t('studio.group.collection.list.details_title')
      "
    />
    <div class="flex gap-24 mt-40">
      <div class="flex-1 flex flex-col gap-24">
        <error-notify
          v-if="isDeleteRequest"
          :message="$t('studio.group.collection.list.details_del_statuts_info')"
        />
        <error-notify
          v-if="isDeleteRequestReject"
          :message="$t('studio.prj_prod.this_prod.del_req_rejected_reason_2')"
        />

        <template v-if="collectionDetails?.restrictStatus === RESTRICT_STATUS.REPORT">
          <complex-error-notify>
            <s-text
              as="p"
              role="text4"
              class="flex items-center gap-4 text-on-surface-elevation-3 mt-4"
            >
              <safe-html
                tag="span"
                :html="
                  $t('studio.group.collection.list.details_restriction_info1', {
                    reportedReason: restrictReason
                  })
                "
              />
            </s-text>
          </complex-error-notify>
        </template>

        <template
          v-if="
            collectionDetails?.restrictStatus === RESTRICT_STATUS.DISPLAY ||
              collectionDetails?.restrictStatus === RESTRICT_STATUS.RATING_DISPLAY ||
              collectionDetails?.restrictStatus === RESTRICT_STATUS.PURCHASE
          "
        >
          <complex-error-notify>
            <s-text
              as="p"
              role="text4"
              class="flex items-center gap-4 text-on-surface-elevation-3 mt-4"
            >
              <safe-html
                tag="span"
                :html="
                  $t('studio.group.collection.list.details_restriction_info2', {
                    restrictionType: restrictReason
                  })
                "
              />
            </s-text>
          </complex-error-notify>
          <complex-error-notify
            v-if="restrictReasonFromAdmin"
            :isRows="false"
            :reason="restrictReason"
            :message="restrictReasonFromAdmin"
          />
        </template>

        <template v-if="collectionDetails?.restrictStatus === RESTRICT_STATUS.COLLECTION_PURCHASE">
          <complex-error-notify>
            <s-text
              as="p"
              role="text4"
              class="flex items-center gap-4 text-on-surface-elevation-3 mt-4"
            >
              <safe-html
                tag="span"
                :html="
                  $t('studio.group.collection.list.details_collection_restriction_info', {
                    restrictionType: restrictReason
                  })
                "
              />
            </s-text>
          </complex-error-notify>
        </template>

        <st-box-collapse
          ref="basicInformationBoxRef"
          :title="$t('studio.group.collection.collection_info')"
          required
          noRefresh
        >
          <collection-basic-information
            :validateField="validateField"
            :translatedValue="translatedValue"
            :isDisabled="isDeleteRequest || isDeleteRequestReject"
            @createCollection="onSimpleCreate"
            @updateTranslatedValue="handleUpdateTranslatedValue"
          />
        </st-box-collapse>

        <template v-if="isEdit">
          <st-box-collapse
            :title="$t('studio.group.collection.register_header_name')"
            required
            noRefresh
          >
            <setup-collection-items
              v-model="currentPageId"
              :isDisabled="isDeleteRequest || isDeleteRequestReject"
              :isSubmitted="isSubmitted"
            />
          </st-box-collapse>

          <st-box-collapse
            :title="$t('studio.prj_prod.this_prod.release_details_sales_setting_title')"
            required
            noRefresh
          >
            <sales-setting
              :isDisabled="isDeleteRequest || isDeleteRequestReject"
              :currentStatus="currentStatus"
              @updateCollectionPriceList="(v) => (collectionPriceList = v)"
            />
          </st-box-collapse>

          <st-box-collapse :title="$t('studio.group.collection.register_plan')" required noRefresh>
            <launch-plan
              :isDisabled="isDeleteRequest || isDeleteRequestReject"
              :isSubmitted="isSubmitted"
            />
          </st-box-collapse>

          <st-box-collapse
            hasReview
            noRefresh
            :title="$t('studio.gnb.common_2depth_banner')"
            @review="onPreview"
          >
            <collection-banner-image :isDisabled="isDeleteRequest || isDeleteRequestReject" />
          </st-box-collapse>

          <div
            v-if="!isDeleteRequest && !isDeleteRequestReject"
            class="mt-40 flex justify-center gap-16"
          >
            <s-button variant="primary" size="lg" class="!min-w-160" @click="onSubmit">
              {{ $t('studio.common.popup_case_save_btn') }}
            </s-button>
          </div>
        </template>
      </div>
      <right-wing>
        <right-wing-item
          :rightWingTitle="$t('studio.group.collection.guide1_1')"
          :numberValue="isEdit ? '01' : undefined"
          class="mt-24"
        >
          <safe-html tag="span" :html="$t('studio.group.collection.guide1_2')" />
        </right-wing-item>
        <template v-if="isEdit">
          <right-wing-item
            :rightWingTitle="$t('studio.group.collection.guide2')"
            numberValue="02"
            class="mt-24"
          >
            {{ $t('studio.group.collection.guide2_1') }}
            <ul class="mt-4 text-sm leading-md text-on-surface-elevation-3">
              <li
                class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
              >
                {{ $t('studio.group.collection.guide2_1_b') }}
              </li>
              <li
                class="relative pl-16 before:absolute before:top-[.9rem] before:left-4 before:w-4 before:h-4 before:rounded-full before:bg-[currentColor]"
              >
                {{ $t('studio.group.collection.guide2_1_c') }}
              </li>
            </ul>
          </right-wing-item>
          <right-wing-item
            :rightWingTitle="$t('studio.group.collection.guide3')"
            numberValue="03"
            class="mt-24"
          >
            {{ $t('studio.group.collection.guide3_1') }}
          </right-wing-item>
          <right-wing-item
            :rightWingTitle="$t('studio.group.collection.guide4')"
            numberValue="04"
            class="mt-24"
          >
            {{ $t('studio.group.collection.guide4_1') }}
          </right-wing-item>
          <right-wing-item
            :rightWingTitle="$t('studio.group.collection.guide5')"
            numberValue="05"
            class="mt-24"
          >
            <safe-html tag="span" :html="$t('studio.group.collection.guide5_1')" />
          </right-wing-item>
        </template>
      </right-wing>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { storeToRefs } from 'pinia';
import { useFieldValue, useForm, useSetFieldError, useSetFieldValue } from 'vee-validate';
import { computed, onUnmounted, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

import { definePageMeta } from '@/.nuxt/imports';
import {
  createCollectionReleaseApi,
  createSimpleCollectionApi,
  fetchCollectionDetailsApi,
  modifyCollectionApi
} from '@/apis/collection.api';
import { fetchProductPageDetailApi } from '@/apis/product-page.api';
import { fetchProductSanctionInquiryApi } from '@/apis/products.api';
import RightWing from '@/components/app/right-wing/index.vue';
import RightWingItem from '@/components/app/right-wing/item.vue';
import CollectionBannerImage from '@/components/collection/collection-banner-image.vue';
import CollectionBasicInformation from '@/components/collection/collection-basic-information.vue';
import ComplexErrorNotify from '@/components/collection/complex-error-notify.vue';
import ErrorNotify from '@/components/collection/error-notify.vue';
import LaunchPlan from '@/components/collection/launch-plan.vue';
import SalesSetting from '@/components/collection/sales-setting.vue';
import SetupCollectionItems from '@/components/collection/setup-collection-items.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StBoxCollapse from '@/components/common/st-box-collapse.vue';
import StTitle from '@/components/common/st-title.vue';
import BannerImageDialog from '@/components/product-page/banner-image-dialog.vue';
import { useApp } from '@/composables/useApp';
import { showAlert, showConfirm, showDialog } from '@/composables/useDialog';
import { MODE } from '@/constants/common.const';
import { currencyCodes } from '@/constants/currency-codes.const';
import { DEFAULT_LOCALE, DEFAULT_LOCALE_UPPERCASE } from '@/constants/locale.const';
import {
  CLAIM_TYPE_LABEL,
  DELETE_REQUEST_STATUS,
  PLAN_STATUS,
  RESTRICT_STATUS
} from '@/constants/products.const';
import {
  RELEASE_PLAN_LIMITED_SALES_STATUS,
  RELEASE_TIME_FORMAT
} from '@/constants/release-plan.const';
import { COLLECTION_URL } from '@/constants/url.const';
import { Confirmation } from '@/enums/common.enum';
import { CurrencyCode } from '@/enums/currency-code.enum';
import { productPageMapper, productPageResponseMapper } from '@/mappers/product-page.mapper';
import { useCollectionStore } from '@/stores/collection.store';
import { useCurrencyStore } from '@/stores/currency.store';
import { useUserStore } from '@/stores/user.store';
import type { BannerImageResponse } from '@/types/banner-image/banner-image-response.type';
import type { CollectionChildDetails, CollectionInfos } from '@/types/collection/collection.type';
import type { CollectionSellingRequest } from '@/types/collection/collection-request.type';
import type { CollectionDetailResponse } from '@/types/collection/collection-response.type';
import type { ImageLanguageType } from '@/types/common/file.type';
import type { FormOption } from '@/types/common/form.type';
import type { CurrencyInfoModel } from '@/types/currency/currency-common.type';
import type { ProductLanguage, SanctionClaimsType } from '@/types/product/product-model.type';
import type { ProductSanctionInquiryResponse } from '@/types/product/product-response.type';
import type { ProductPageFormType } from '@/types/product-page.type';
import type { ProductPageDetailResponse } from '@/types/product-page/product-page-response.type';
import type { PriceListType } from '@/types/product-selling-price/product-selling-price.type';
import type {
  ReleasePlanInformationPlanPage,
  ReleasePlanInformationPlanPrePurchase,
  ReleasePlanInformationPlanRelease
} from '@/types/release-plan/release-plan.type';
import { camelToSnake } from '@/utils/api.util';
import { redirectTo } from '@/utils/common.util';
import { displayPriceAfterDiscount, paymentPriceAfterDiscount } from '@/utils/price.util';
import { getDisplaySanctionText } from '@/utils/product.util';
import { hasInputAllRequiredFields } from '@/utils/product-page.util';

definePageMeta({ middleware: 'check-menu-writable-middleware', editModeAccessible: true });

interface CollectionForm {
  groupId: string;
  childrenProductNos: number[];
  basicProductInfos: {
    languageCd: string;
    productName: string;
    developer: string;
    publisher: string;
  };
  productInfos: CollectionInfos[];
  productInfo: CollectionInfos[];
  pageInfo: ProductPageFormType;
  priceInfo: CollectionSellingRequest;
  productPlan: {
    page: ReleasePlanInformationPlanPage;
    prePurchase: ReleasePlanInformationPlanPrePurchase;
    release: ReleasePlanInformationPlanRelease;
  };
  collectionDiscountType: Confirmation;
  currencyStandard: string;
  priceStandard: string;
  bannerImages: {
    files: ImageLanguageType[];
  };
  genreTagNos: string[];
  defaultGenre: FormOption;
  childProducts?: CollectionChildDetails[];
  productPageDetails?: ProductPageDetailResponse;
  planStatus?: string;
  isValid?: boolean;
  saleSettingErrors?: string[];
}

const CORRECT_RATIO = ['RATIO_2X2', 'RATIO_1X2', 'RATIO_2X1'];

const app = useApp();

const { t } = useI18n();

const route = useRoute();
const { mode } = route.params;
const { productNo } = route.query;

const userStore = useUserStore();
const { selectedGroupId, selectedGroupInfo } = storeToRefs(userStore);

const collectionStore = useCollectionStore();
const { emptySelectedProductList } = collectionStore;

const currencyStore = useCurrencyStore();
const { fetchCurrencyInfos } = currencyStore;

const isSubmitted = ref<boolean>(false);
const duplicateProductNameError = ref<string | undefined>('');
const basicInformationBoxRef = ref<InstanceType<typeof StBoxCollapse> | undefined>();
const exchangeRates = ref<CurrencyInfoModel[]>();
const currentPageId = ref<string>('');
const currentStatus = ref<string>('');
const translatedValue = ref<CollectionInfos[]>([]);
const productPageInfos = ref<ProductPageDetailResponse>();
const collectionDetails = ref<CollectionDetailResponse>();
const collectionPriceList = ref<PriceListType[]>([]);
const restrictInfo = ref<ProductSanctionInquiryResponse>();
const restrictReason = ref<string>('');
const restrictReasonFromAdmin = ref<string>('');

const DEFAULT_DATE_RELEASE_AT: number = DateTime.local().plus({ hours: 1, days: 7 }).toMillis();

const isDeleteRequest = computed(() => {
  if (collectionDetails.value?.deleteRequestStatus) {
    return (
      collectionDetails.value?.deleteRequestStatus !== DELETE_REQUEST_STATUS.NONE &&
      collectionDetails.value?.deleteRequestStatus !== DELETE_REQUEST_STATUS.REJECT
    );
  }
  return false;
});

const isDeleteRequestReject = computed(() => {
  if (collectionDetails.value?.deleteRequestStatus) {
    return collectionDetails.value?.deleteRequestStatus === DELETE_REQUEST_STATUS.REJECT;
  }
  return false;
});

const isEdit = computed(() => mode !== MODE.CREATE && Number(productNo) > 0);

const { validate, values, resetForm, validateField, errors } = useForm<CollectionForm>({
  initialValues: {
    saleSettingErrors: [],
    groupId: selectedGroupId.value,
    childrenProductNos: [],
    basicProductInfos: {
      languageCd: selectedGroupInfo?.value?.languageCd ?? 'ko',
      productName: '',
      developer: '',
      publisher: ''
    },
    productInfos: [],
    productInfo: [],
    pageInfo: {},
    priceInfo: {
      paidYn: Confirmation.YES,
      recommendSelectYn: Confirmation.NO,
      defaultPrice: 0,
      priceList: []
    },
    productPlan: {
      page: {
        pageOpenedAt: DateTime.local().plus({ hours: 1 }).toMillis(),
        earlyAccessYn: Confirmation.YES,
        earlyAccessSchedule: {
          startedAt: 0,
          endedAt: 0
        }
      },
      prePurchase: {
        prePurchaseYn: Confirmation.NO,
        prePurchaseSchedule: {
          startedAt: DateTime.local().plus({ hours: 1 }).toMillis(),
          endedAt: DateTime.local().plus({ hours: 1, days: 7 }).toMillis()
        }
      },
      release: {
        releaseTimeFormat: RELEASE_TIME_FORMAT.COMING_SOON,
        releasedAt: DEFAULT_DATE_RELEASE_AT,
        sale: {
          releaseDiscountYn: Confirmation.YES,
          releaseDiscountRate: undefined,
          releaseDiscountDays: 1,
          saleLimitType: RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
          saleLimitCount: undefined,
          saleLimitEndedAt: undefined
        }
      }
    },
    collectionDiscountType: Confirmation.YES,
    currencyStandard: 'USD',
    priceStandard: 'store',
    bannerImages: {
      files: [
        {
          fileId: '',
          linkCdn: '',
          name: '',
          ratio: 'RATIO_2X2',
          lang: 'ko'
        },
        {
          fileId: '',
          linkCdn: '',
          name: '',
          ratio: 'RATIO_1X2',
          lang: 'ko'
        },
        {
          fileId: '',
          linkCdn: '',
          name: '',
          ratio: 'RATIO_2X1',
          lang: 'ko'
        }
      ]
    },
    genreTagNos: [],
    defaultGenre: undefined,
    productPageDetails: {},
    planStatus: PLAN_STATUS.NONE
  }
});

const convertIndexToRatio = (index: number) => {
  switch (index) {
    case 0:
      return 'RATIO_2X2';
    case 1:
      return 'RATIO_1X2';
    case 2:
      return 'RATIO_2X1';
    default:
      return 'RATIO_2X2';
  }
};

const productName = useFieldValue<string>('basicProductInfos.productName');
const setProductNameError = useSetFieldError('basicProductInfos.productName');

const productInfo = useFieldValue<CollectionInfos[]>('productInfos');
const setProductPageDetails = useSetFieldValue('productPageDetails');

const handleUpdateTranslatedValue = (languages: ProductLanguage[]) => {
  translatedValue.value = languages.map((l: ProductLanguage) => {
    return {
      languageCd: l.langCode,
      productName: l.productName,
      developer: l.developer,
      publisher: l.publisher
    };
  });
};

// check item only change default language
const isChangeDefaultLanguageOnly = computed(() => {
  const defaultLanguage = {
    languageCd: selectedGroupInfo?.value?.languageCd,
    productName: productName.value,
    developer: values.basicProductInfos.developer,
    publisher: values.basicProductInfos.publisher
  };
  const originalDefaultLanguage = translatedValue.value.find(
    (t: CollectionInfos) => t.languageCd === selectedGroupInfo?.value?.languageCd
  );

  if (!defaultLanguage || !originalDefaultLanguage) {
    return false;
  }
  return !isEqual(defaultLanguage, originalDefaultLanguage);
});

const isNotChangeSettingLanguages = computed(() => {
  const settingLanguages = values.productInfos.filter(
    (info: CollectionInfos) => info.languageCd !== selectedGroupInfo?.value?.languageCd
  );
  const originalLanguages = translatedValue.value.filter(
    (t: CollectionInfos) => t.languageCd !== selectedGroupInfo?.value?.languageCd
  );

  if (settingLanguages.length !== originalLanguages.length) {
    return false;
  }
  return settingLanguages.every((info: CollectionInfos, index: number) =>
    isEqual(info, originalLanguages[index])
  );
});

const onSimpleCreate = async () => {
  if (
    !(await app.checkMenuWritable({
      redirectUrl: COLLECTION_URL
    }))
  ) {
    return;
  }

  if (isChangeDefaultLanguageOnly.value && isNotChangeSettingLanguages.value) {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_ai_modified'),
      confirmLabel: t('studio.common.popup_case_save_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });
    if (!confirm) {
      return;
    }
  }

  const request = {
    groupId: values.groupId,
    productInfos: values.productInfos,
    genreTagNos: values.genreTagNos.map((g: string) => Number(g)),
    defaultGenreTagNo: Number(values.defaultGenre.value || 0)
  };
  const result = await createSimpleCollectionApi(request);

  if (result?.productNo) {
    await showAlert({
      content: t('studio.common.popup_case_d_complete_save')
    });
    redirectTo(`/${selectedGroupId.value}/collections/${MODE.EDIT}?productNo=${result.productNo}`);
  }
};

const getExchangeInfo = (currencyCode: string) => {
  return exchangeRates.value?.find((rate: CurrencyInfoModel) => rate.currencyCode === currencyCode);
};

const onSubmit = async () => {
  if (
    !(await app.checkMenuWritable({
      redirectUrl: COLLECTION_URL
    }))
  ) {
    return;
  }
  isSubmitted.value = true;
  duplicateProductNameError.value = errors.value['basicProductInfos.productName'];

  const result = await validate();

  if (!result.valid || duplicateProductNameError.value) {
    if (duplicateProductNameError.value) {
      setProductNameError(duplicateProductNameError.value);
    }
    return;
  }

  if (isChangeDefaultLanguageOnly.value && isNotChangeSettingLanguages.value) {
    const confirm = await showConfirm({
      content: t('studio.common.popup_case_ai_modified'),
      confirmLabel: t('studio.common.popup_case_save_btn'),
      cancelLabel: t('studio.common.popup_case_cancel_btn'),
      cancelVariant: 'outline'
    });
    if (!confirm) {
      return;
    }
  }

  if (!values.isValid) {
    return;
  }

  if (!currentPageId.value) {
    return;
  } else {
    productPageInfos.value = await fetchProductPageDetailApi(currentPageId.value);
    setProductPageDetails(productPageInfos.value);
  }

  if (!productPageInfos.value) {
    return;
  }

  if (
    !hasInputAllRequiredFields(
      productPageResponseMapper(
        productPageInfos.value,
        selectedGroupInfo?.value?.languageCd.replace('-', '_').toUpperCase() ||
          DEFAULT_LOCALE_UPPERCASE
      ),
      true,
      selectedGroupInfo?.value?.languageCd,
      true
    )
  ) {
    return;
  }

  const productPlanMapper = {
    page: {
      pageOpenedAt: DateTime.fromJSDate(new Date(values.productPlan.page?.pageOpenedAt || 0))
        .startOf('minute')
        .toMillis(),
      earlyAccessYn: values.productPlan.page.earlyAccessYn,
      earlyAccessSchedule: {
        startedAt: DateTime.fromJSDate(
          new Date(values.productPlan.page.earlyAccessSchedule?.startedAt || 0)
        )
          .startOf('minute')
          .toMillis(),
        endedAt: DateTime.fromJSDate(
          new Date(values.productPlan.page.earlyAccessSchedule?.endedAt || 0)
        )
          .startOf('minute')
          .toMillis()
      }
    },
    prePurchase: {
      prePurchaseYn: values.productPlan.prePurchase.prePurchaseYn,
      prePurchaseSchedule: {
        startedAt: DateTime.fromJSDate(
          new Date(values.productPlan.prePurchase.prePurchaseSchedule?.startedAt || 0)
        )
          .startOf('minute')
          .toMillis(),
        endedAt: DateTime.fromJSDate(
          new Date(values.productPlan.prePurchase.prePurchaseSchedule?.endedAt || 0)
        )
          .startOf('minute')
          .toMillis()
      }
    },
    release: {
      releaseTimeFormat: values.productPlan.release.releaseTimeFormat,
      releasedAt: DateTime.fromJSDate(
        new Date(values.productPlan.release.releasedAt || DEFAULT_DATE_RELEASE_AT)
      )
        .startOf('minute')
        .toMillis(),
      sale: {
        releaseDiscountYn: values.productPlan.release.sale?.releaseDiscountYn,
        releaseDiscountRate: values.productPlan.release.sale?.releaseDiscountRate,
        releaseDiscountDays: values.productPlan.release.sale?.releaseDiscountDays,
        saleLimitType: values.productPlan.release.sale?.saleLimitType,
        saleLimitCount: values.productPlan.release.sale?.saleLimitCount,
        saleLimitEndedAt: DateTime.fromJSDate(
          new Date(values.productPlan.release.sale?.saleLimitEndedAt || 0)
        )
          .startOf('minute')
          .toMillis()
      }
    }
  };

  const releaseDiscountRate = values.productPlan.release.sale?.releaseDiscountRate || 0;

  const collectionFinalPrice = collectionPriceList.value.map((p: PriceListType) => {
    return {
      ...p,
      salesPrice: paymentPriceAfterDiscount(
        p.salesPrice,
        releaseDiscountRate,
        p.salesCurrencyCode ?? CurrencyCode.Usd
      ),
      displayPrice: displayPriceAfterDiscount(
        p.salesPrice,
        releaseDiscountRate,
        p.displayCurrencyCode,
        getExchangeInfo(p.displayCurrencyCode)?.exchangeRate
      ),
      originalDisplayPrice: p.displayPrice,
      originalSalesPrice: p.salesPrice
    };
  });

  const request = {
    page_info: productPageMapper(
      productPageResponseMapper(
        productPageInfos.value,
        selectedGroupInfo?.value?.languageCd.replace('-', '_').toUpperCase() ||
          DEFAULT_LOCALE_UPPERCASE
      ),
      selectedGroupInfo?.value?.languageCd.replace('-', '_').toUpperCase() ||
        DEFAULT_LOCALE_UPPERCASE
    ),
    ...camelToSnake({
      groupId: values.groupId,
      productInfos: values.productInfo,
      priceInfo: {
        ...values.priceInfo,
        priceList: collectionFinalPrice
      },
      productPlan: productPlanMapper,
      childrenProductNos: values.childrenProductNos,
      bannerImages: {
        files: [...Array(3).keys()].map((_: number, index: number) => {
          if (values.bannerImages.files[index]) {
            return {
              linkCdn: values.bannerImages.files[index].linkCdn || '',
              fileId: values.bannerImages.files[index].fileId || '',
              fileName: values.bannerImages.files[index].name || '',
              ratio: convertIndexToRatio(index),
              lang: values.bannerImages.files[index].lang,
              fileType: values.bannerImages.files[index].fileTypeRaw ?? values.bannerImages.files[index].fileType
            };
          }
          return {
            linkCdn: '',
            fileId: '',
            fileName: '',
            ratio: convertIndexToRatio(index),
            lang: 'ko',
            fileType: ''
          };
        })
      },
      genreTagNos: values.genreTagNos.map((g: string) => Number(g)),
      defaultGenreTagNo: Number(values.defaultGenre.value)
    })
  };

  // The collection must have at least 2 products
  if (request.children_product_nos.length < 2) {
    return;
  }

  const dialog = await showConfirm({
    content: t('studio.group.collection.register_pass_validation'),
    confirmLabel: t('studio.common.popup_case_apply_btn'),
    cancelLabel: t('studio.common.popup_case_cancel_btn'),
    cancelVariant: 'outline'
  });

  if (!dialog) {
    return;
  }

  try {
    if (currentStatus.value !== PLAN_STATUS.NONE) {
      const result = await modifyCollectionApi(Number(productNo), request);
      if (result?.success) {
        redirectTo(`/${selectedGroupId.value}/collections`);
      }
    } else {
      const result = await createCollectionReleaseApi(Number(productNo), request);
      if (result?.success) {
        redirectTo(`/${selectedGroupId.value}/collections`);
      }
    }
  } catch (error) {}
};

const onPreview = async () => {
  await showDialog({
    component: shallowRef(BannerImageDialog),
    props: {
      productName: productName.value,
      image2x2: values.bannerImages.files?.[0]?.linkCdn,
      image1x2: values.bannerImages.files?.[1]?.linkCdn,
      image2x1: values.bannerImages.files?.[2]?.linkCdn
    }
  });
};

const init = async () => {
  if (mode === MODE.CREATE) {
    emptySelectedProductList();
    translatedValue.value = productInfo.value;
  }
  if (mode === MODE.EDIT) {
    if (!productNo) {
      redirectTo(`/${selectedGroupId.value}/collections`);
    }

    const result = await fetchCollectionDetailsApi(Number(productNo));

    collectionDetails.value = result;

    if (result?.restrictStatus) {
      if (result?.restrictStatus === RESTRICT_STATUS.REPORT) {
        const res = await fetchProductSanctionInquiryApi(Number(productNo));
        if (res) {
          restrictInfo.value = res;

          const reasons = res.currentClaims
            .map((r: SanctionClaimsType) => r.claimType)
            .map((type: string) => t(CLAIM_TYPE_LABEL[type as keyof typeof CLAIM_TYPE_LABEL]));

          restrictReason.value = reasons.join(', ');

          if (res.currentClaims.length === 0) {
            restrictReason.value = result?.claimTypes
              .map((type: string) => t(CLAIM_TYPE_LABEL[type as keyof typeof CLAIM_TYPE_LABEL]))
              .join(', ');
          }
        }
      }
      if (
        result?.restrictStatus === RESTRICT_STATUS.DISPLAY ||
        result?.restrictStatus === RESTRICT_STATUS.RATING_DISPLAY ||
        result?.restrictStatus === RESTRICT_STATUS.PURCHASE
      ) {
        restrictReasonFromAdmin.value = result.restrictReason;
        restrictReason.value = t(getDisplaySanctionText(result.restrictStatus));
      }
      if (result?.restrictStatus === RESTRICT_STATUS.COLLECTION_PURCHASE) {
        restrictReason.value = t(getDisplaySanctionText(result.restrictStatus));
      }
    }

    exchangeRates.value = await fetchCurrencyInfos(currencyCodes);

    const productPlan = {
      page: {
        pageOpenedAt:
          result?.plans?.page?.pageOpenedAt || DateTime.local().plus({ hours: 1 }).toMillis(),
        earlyAccessYn: result?.plans?.page?.earlyAccessYn || Confirmation.NO,
        earlyAccessSchedule: {
          startedAt: result?.plans?.page?.earlyAccessSchedule?.startedAt,
          endedAt: result?.plans?.page?.earlyAccessSchedule?.endedAt
        }
      },
      prePurchase: {
        prePurchaseYn: result?.plans?.prePurchase?.prePurchaseYn || Confirmation.NO,
        prePurchaseSchedule: {
          startedAt:
            result?.plans?.prePurchase?.prePurchaseSchedule?.startedAt ||
            DateTime.local().plus({ hours: 1 }).toMillis(),
          endedAt:
            result?.plans?.prePurchase?.prePurchaseSchedule?.endedAt ||
            DateTime.local().plus({ hours: 1, days: 7 }).toMillis()
        }
      },
      release: {
        releaseTimeFormat:
          result?.plans?.release?.releaseTimeFormat || RELEASE_TIME_FORMAT.COMING_SOON,
        releasedAt: result?.plans?.release?.releasedAt || DEFAULT_DATE_RELEASE_AT,
        sale: {
          releaseDiscountYn: result?.plans?.release?.sale?.releaseDiscountYn || Confirmation.NO,
          releaseDiscountRate: result?.plans?.release?.sale?.releaseDiscountRate,
          releaseDiscountDays: result?.plans?.release?.sale?.releaseDiscountDays,
          saleLimitType:
            result?.plans?.release?.sale?.saleLimitType || RELEASE_PLAN_LIMITED_SALES_STATUS.NONE,
          saleLimitCount: result?.plans?.release?.sale?.saleLimitCount,
          saleLimitEndedAt: result?.plans?.release?.sale?.saleLimitEndedAt
        }
      }
    };

    const defaultData = {
      collectionDiscountType: Confirmation.YES,
      currencyStandard: 'USD',
      priceStandard: 'store',
      priceInfo: {
        paidYn: Confirmation.YES,
        recommendSelectYn: Confirmation.NO,
        defaultPrice: 0,
        priceList: []
      },
      productPlan
    };

    if (!result) {
      return;
    }

    resetForm({
      values: {
        groupId: selectedGroupId.value,
        childrenProductNos: result.childProducts.map((p: CollectionChildDetails) => p.productNo),
        basicProductInfos: result.productInfo
          .filter(
            (p: CollectionInfos) =>
              p.languageCd === (selectedGroupInfo.value?.languageCd || DEFAULT_LOCALE)
          )
          .map((p: CollectionInfos) => ({
            languageCd: p.languageCd,
            productName: p.productName,
            developer: p.developer,
            publisher: p.publisher
          }))[0],
        // productPlan: result.plans,
        pageInfo: result.pageInfo,
        bannerImages: {
          files: result.bannerImages
            .sort((a: BannerImageResponse, b: BannerImageResponse) => {
              return CORRECT_RATIO.indexOf(a.ratio) - CORRECT_RATIO.indexOf(b.ratio);
            })
            .map((b: BannerImageResponse) => ({
              ...b,
              lang: selectedGroupInfo.value?.languageCd || 'ko',
              name: b.fileName
            }))
        },
        genreTagNos: result.genreTagNos.map((g: number) => g.toString()),
        defaultGenre: {
          value: (result.defaultGenreTagNo || '').toString(),
          label: ''
        },
        childProducts: result.childProducts,
        productInfo: result.productInfo,
        productInfos: result.productInfo,
        productPageDetails: productPageInfos.value,
        planStatus: result.planStatus,
        ...defaultData
      }
    });

    if (result.pageInfo?.pageId) {
      currentPageId.value = result.pageInfo?.pageId;
      const page = await fetchProductPageDetailApi(result.pageInfo?.pageId);
      setProductPageDetails(page);
    }

    currentStatus.value = result.planStatus;
    translatedValue.value = result.productInfo;

    basicInformationBoxRef.value?.toggleCollapse();
  }
};

init();

onUnmounted(() => {
  emptySelectedProductList();
});
</script>
